import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import Header from '../../AppComponents/Layout/Header'
import Footer from '../../AppComponents/Layout/Footer'
import { RequestVerifyTokenPasswordRecovery } from '../../Utilities/RequestVerifyTokenPasswordRecovery'
import { RequestChangePasswordFromPasswordRecovery } from '../../Utilities/RequestChangePasswordFromPasswordRecovery'
import { useSearchParams } from "react-router-dom"
import { useState } from 'react'
import { useEffect } from 'react'


/**
 * 
 * @description Componente de UI de la página de recuperación de contraseña
 */
export default function AudiometerPasswordRecovery() {
    const [t] = useTranslation('global');
    const [loading, setLoading] = useState(true);
    const [successVerify, setSuccessVerify] = useState(false);
    const [complete, setComplete] = useState(false);
    const [passwordInvalid, setPasswordInvalid] = useState(false);
    const [ queryParameters ] = useSearchParams();

    useEffect(() => {
        RequestVerifyTokenPasswordRecovery(
          queryParameters.get("uid"),
          queryParameters.get("token")
        ).then((data) => {
            setLoading(false)
            try {
                console.log(data)
                if (data.success) {
                    setSuccessVerify(true)
                } else {
                    setSuccessVerify(false)
                }
            } catch {
                setSuccessVerify(false)
            }
          
        })
      }, [queryParameters]);

    return (
        <>
            <Helmet>
                <title>uSound Audiómetro | Password Recovery</title>
            </Helmet>

            <Header />

            <main id='passwordRecovery'>

                <div className="col-md-12" style={{paddingTop: "160px", backgroundColor: "#6766EB"}}>
                    <div className="row justify-content-md-center" style={{paddingBottom: "120px"}}>
                        <div className="col-md-6 col-sm-6 animate justify-content-md-center" style={{borderRadius: "10px", backgroundColor: "#FFFFFF", paddingBottom: "40px"}}>
                            <div className="row justify-content-md-center">
                                <div className="col-md-8 justify-content-md-center" style={{paddingTop: "40px"}}>
                                    {!complete ? 
                                        <> 
                                            {!loading ? 
                                                <> 
                                                    { successVerify ?
                                                        <>
                                                            <div className="row" style={{paddingTop: "40px"}}>
                                                                <h3 className="text-format" style={{textAlign: "center"}}>{t('AudiometerPasswordRecovery.title')}</h3>
                                                            </div>
                                                            <div className="row" style={{paddingTop: "40px"}}>
                                                                <form action="" class="form-horizontal" onSubmit={(e) => e.preventDefault()}>
                                                                    <div className="row form-group justify-content-md-center">
                                                                        <div className="col-md-8">
                                                                            <label className="text-format">{t('AudiometerPasswordRecovery.new_password')}</label>
                                                                            <input id="id_new_password1" name="new_password1" className="form-control email" style={{borderRadius: "20px"}} minlength="8" placeholder="" data-placement="top" data-trigger="manual" type="password"/>
                                                                            <br/>
                                                                            <label className="text-format">{t('AudiometerPasswordRecovery.confirm_password')}</label>
                                                                            <input id="id_new_password2" name="new_password2" className="form-control email" style={{borderRadius: "20px"}} minlength="8" placeholder="" data-placement="top" data-trigger="manual" type="password"/>
                                                                            <br/>
                                                                            <br/>
                                                                            <br/>
                                                                            {passwordInvalid && (
                                                                                <>
                                                                                    <p class="text-format" style={{color: "red"}}>{t('AudiometerPasswordRecovery.error_match_password')}</p>
                                                                                    <br/>
                                                                                    <br/>
                                                                                </>
                                                                            )}
                                                                            <button type="" className="btn text-format" style={{backgroundColor: "#4831D8", color: "#FFFFFF", borderRadius: "50px", width: "100%"}} onClick={
                                                                                (event) => {
                                                                                    var pass1 = document.getElementById('id_new_password1').value;
                                                                                    var pass2 = document.getElementById('id_new_password2').value;
                                                                                    if (pass1 === pass2) {
                                                                                        RequestChangePasswordFromPasswordRecovery(
                                                                                            queryParameters.get("uid"),
                                                                                            pass2
                                                                                        ).then(data => {
                                                                                            setLoading(false)
                                                                                            try {
                                                                                                if (data.success) {
                                                                                                    setComplete(true)
                                                                                                } else {
                                                                                                    setSuccessVerify(false)
                                                                                                }
                                                                                            } catch {
                                                                                                setSuccessVerify(false)
                                                                                            }
                                                                                        
                                                                                        })
                                                                                    } else {
                                                                                        setPasswordInvalid(true)
                                                                                    }
                                                                                    
                                                                                }
                                                                            }>{t('AudiometerPasswordRecovery.button_confirm')}</button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </>
                                                    : 
                                                        <>
                                                            <br/>
                                                            <br/>
                                                            <br/>
                                                            <p class="text-format">{t('AudiometerPasswordRecovery.error_token_1')}<br/>{t('AudiometerPasswordRecovery.error_token_2')}</p>
                                                        </>
                                                    } 
                                                </> 
                                            : 
                                                <div class="row justify-content-md-center animate" style={{ borderRadius: "10px", backgroundColor: "#FFFFFF", paddingBottom: "40px" }}>
                                                    <div class="col-md-10 col-sm-10 justify-content-md-center" style={{ textAlign: "center", paddingTop: "100px" }}>
                                                        <div class="col-md-12" style={{ paddingBottom: "60px" }}>
                                                            <h3 class="text-format">{t('AudiometerPasswordRecovery.loading')}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </> 
                                    : 
                                        <div class="row justify-content-md-center animate" style={{ borderRadius: "10px", backgroundColor: "#FFFFFF", paddingBottom: "40px" }}>
                                            <div class="col-md-10 col-sm-10 justify-content-md-center" style={{ textAlign: "center", paddingTop: "100px" }}>
                                                <div class="col-md-12" style={{ paddingBottom: "60px" }}>
                                                    <h3 class="text-format">{t('AudiometerPasswordRecovery.success_1')}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </main>

            <Footer />
        
        </>
        
    )
}