import { useTranslation } from "react-i18next";

import Laura from '../../../Assets/Home/laura_banner.webp'

export default function Banner() {

    const [t] = useTranslation('global')

    return (
        <section id="banner" className="d-flex align-items-center">

            <div className="d-flex flex-wrap align-items-center justify-content-center mx-auto col-lg-8 mt-5">
                <div className="col-lg-6">
                    <img loading="lazy" src={Laura} alt="Banner" className="w-100" />
                </div>
                <article className="col-12 col-lg-5 ms-lg-5 px-4 pt-4 pt-xl-5 pb-0">
                    <h1 className="text-white fw-bold">{t('home.banner.title')}</h1>
                    <p className="text-white mt-4 mb-5">
                        {t('home.banner.description')}
                    </p>
                    <button type="button" className="bannerButton"
                        onClick={() => {
                            let element = document.getElementById("caracteristicas");
                            element.scrollIntoView({ behavior: "smooth" });
                        }}
                    >
                        {t('home.learn_more_button')}
                    </button>
                </article>
            </div>

        </section>
    )
}