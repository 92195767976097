import { useState } from "react";

import Video from '../../../Assets/Landing/ic_video.svg'
import Misc from '../../../Assets/Home/misc.svg'
import Aerea from '../../../Assets/EvaluationsIcons/ic_aerea.svg'
import AereaAuto from '../../../Assets/EvaluationsIcons/ic_aereaauto.svg'
import OseoAuto from '../../../Assets/EvaluationsIcons/ic_oseoauto.svg'
import Oseo from '../../../Assets/EvaluationsIcons/ic_oseo.svg'
import LDL from '../../../Assets/EvaluationsIcons/ic_ldl.svg'
import SISI from '../../../Assets/EvaluationsIcons/ic_sisi.svg'
import Fowler from '../../../Assets/EvaluationsIcons/ic_fowler.svg'
import Logo from '../../../Assets/EvaluationsIcons/ic_logoaudiometria.svg'
import Tinnitumetria from '../../../Assets/EvaluationsIcons/ic_tinnitumetria.svg'
import Tinnitus from '../../../Assets/EvaluationsIcons/ic_tinnitus.svg'
import VideoModal from "../CTA/VideoModal";

export default function Estudios() {

    const [showVideo, setShowVideo] = useState(false);

    return (
        <div id="landing_estudios_container">
            <img src={Misc} alt="" className='miscDown' />
            <div id="landing_estudios">
                <div className="pb-lg-4">
                    <div className="col-12 col-lg-10 col-xl-6 mx-auto">
                        <h2 className="first_h2">Nuestros Estudios</h2>
                        <div className="test_container">
                            <div className="d-flex justify-content-around test_row">
                                <span className="text-center test_card mb-sm-5">
                                    <img src={Oseo} alt="" />
                                    <p>
                                        Estudio por
                                    </p>
                                    <span>
                                        Vía Ósea
                                    </span>
                                </span>
                                <span className="text-center test_card mb-sm-5">
                                    <img src={Aerea} alt="" />
                                    <p>
                                        Estudio por
                                    </p>
                                    <span>
                                        Vía Aérea
                                    </span>
                                </span>
                                <span className="text-center test_card mb-sm-5">
                                    <img src={Logo} alt="" />
                                    <p>
                                        Estudio
                                    </p>
                                    <span>
                                        Logoaudiometría
                                    </span>
                                </span>
                                <span className="text-center test_card mb-sm-5">
                                    <img src={Tinnitumetria} alt="" />
                                    <p>
                                        Estudio
                                    </p>
                                    <span>
                                        Tinnitumetría
                                    </span>
                                </span>
                                <span className="text-center test_card mb-sm-5">
                                    <img src={LDL} alt="" />
                                    <p>
                                        Estudio
                                    </p>
                                    <span>
                                        L.D.L.
                                    </span>
                                </span>
                            </div>
                            <div className="d-flex justify-content-around test_row">
                                <span className="text-center test_card">
                                    <img src={OseoAuto} alt="" />
                                    <p>
                                        Estudio por
                                    </p>
                                    <span>
                                        Vía Ósea
                                    </span>
                                    <small className="d-block">
                                        automática
                                    </small>
                                </span>
                                <span className="text-center test_card">
                                    <img src={AereaAuto} alt="" />
                                    <p>
                                        Estudio por
                                    </p>
                                    <span>
                                        Vía Aérea
                                    </span>
                                    <small className="d-block">
                                        automática
                                    </small>
                                </span>
                                <span className="text-center test_card">
                                    <img src={SISI} alt="" />
                                    <p>
                                        Estudio
                                    </p>
                                    <span>
                                        S.I.S.I.
                                    </span>
                                </span>
                                <span className="text-center test_card">
                                    <img src={Tinnitus} alt="" />
                                    <p>
                                        Estudio
                                    </p>
                                    <span>
                                        Test inc. tinnitus
                                    </span>
                                </span>
                                <span className="text-center test_card">
                                    <img src={Fowler} alt="" />
                                    <p>
                                        Estudio
                                    </p>
                                    <span>
                                        A.B.L.B. (Fowler)
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5">
                    <h2 className="mx-auto text-center second_h2">
                        ¡Realiza los estudios en simples
                        pasos y donde quieras!
                    </h2>
                    <div className="mt-5 d-flex flex-wrap align-items-center justify-content-center">
                        <div className="d-flex">
                            <img src={Video} alt="" className="video_img mb-sm-5" />
                            <h3
                                className="ms-3 mb-sm-5 video_h3"
                                onClick={() => setShowVideo(true)}
                            >
                                Ver video
                            </h3>
                            <VideoModal
                                showVideo={showVideo}
                                setShowVideo={setShowVideo}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}