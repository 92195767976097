import { Helmet } from "react-helmet";

import Footer from "../../AppComponents/Layout/Footer";
import Header from "../../AppComponents/Layout/Header";

/**
 * 
 * @description Componente de UI que se muestra cuando la URL no existe
 */
export default function PageNotFound() {
    return (
        <>

            <Helmet>
                <title>uSound Audiómetro | 404</title>
            </Helmet>

            <Header />

            <main id="ErrorMain">
                <h2 className="fs-1 text-center">
                    Página no encontrada
                </h2>
            </main>

            <Footer />
        </>
    )
}