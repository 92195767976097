import { Suspense, lazy } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import Flag from 'react-world-flags'

import Header from '../../AppComponents/Layout/Header'
import Footer from '../../AppComponents/Layout/Footer'
import ProfileLoader from './Components/ProfileLoader'

// FOTOS DE NUESTRO EQUIPO
import Eze from '../../Assets/Team/eze.png'
import Tavo from '../../Assets/Team/Gustavo.png'
import Zeque from '../../Assets/Team/zeque.png'
import Mati from '../../Assets/Team/Mati.png'
import Pato from '../../Assets/Team/Pato.png'
import Rafa from '../../Assets/Team/rafa.png'
import Cris from '../../Assets/Team/cris.png'
import Pame from '../../Assets/Team/pame.png'
import Nacho from '../../Assets/Team/Ignacio.png'
import Ale from '../../Assets/Team/ale.png'
import Agus from '../../Assets/Team/agus.png'
import Pedro from '../../Assets/Team/pedro.png'
import Adrian from '../../Assets/Team/adrian.png'
import Mari from '../../Assets/Team/mari.png'
import Cana from '../../Assets/Team/cana.png'
import MaxiOrtiz from '../../Assets/Team/maximiliano_ortiz.png'
import AlineAndrade from '../../Assets/Team/aline_andrade.png'
import Abraham from '../../Assets/Team/abraham.png'
import Nico from '../../Assets/Team/nico.png'
import FrancoSmith from '../../Assets/Team/franco_smith.png'
import Elu from '../../Assets/Team/elu.png'
import Ceci from '../../Assets/Team/ceci.png'
import NaiaraGalieta from '../../Assets/Team/naiara_galieta.png'
import Vivi from '../../Assets/Team/vivi.png'
import LethiciaSantos from '../../Assets/Team/lethicia_santos.png'
import Paola from '../../Assets/Team/paola.png'

// FOTOS DE EQUIPO COMERCIAL
import Maxi from '../../Assets/Team/maxi.png'
import Guille from '../../Assets/Team/guille.png'
import Walter from '../../Assets/Team/walter.png'

// BANDERAS
import Arg from '../../Assets/svg/arg.svg'
import Chi from '../../Assets/svg/chi.svg'
import Col from '../../Assets/svg/col.svg'

// FOTOS E IMAGENES DE INVERSORES Y MENTORES
import JuanCollado from '../../Assets/Investors_advisors/collado.png'
import AxelBarcelo from '../../Assets/Investors_advisors/barcelo.png'
import FabianaOliver from '../../Assets/Investors_advisors/oliver.png'
import ClaudiaBerraondo from '../../Assets/Investors_advisors/berraondo.png'
import CinthyaRoth from '../../Assets/Investors_advisors/roth.png'
import GabrielDeboli from '../../Assets/Investors_advisors/deboli.png'
import PabloBaldomaJones from '../../Assets/Investors_advisors/baldoma.png'
import Wayra from '../../Assets/Investors_advisors/Wayra_black.png'
import PuertoAsis from '../../Assets/Investors_advisors/puerto_asis.svg'
import Incubando from '../../Assets/Investors_advisors/incubando.svg'
import Quarux from '../../Assets/Investors_advisors/quarux.svg'
import Lphub from '../../Assets/Investors_advisors/lphub.svg'
import Mvd500 from '../../Assets/Investors_advisors/500mvd.svg'

// RECONOCIMIENTOS
import UsaEmbassy from '../../Assets/Awards/embajadaEEUU-ARG.png'
import Bid from '../../Assets/Awards/Logo_BID_es.png'
import Innovadores from '../../Assets/Awards/innovadores.png'
import MassChallenge from '../../Assets/Awards/Masschallenge.png'
import ChinaHealthSummit from '../../Assets/Awards/China_health_summit.png'
import M2D2 from '../../Assets/Awards/m2d2.png'
import Naves from '../../Assets/Awards/NAVES.png'
import ImagineCup from '../../Assets/Awards/ImagineCup.png'
import PremiosSadosky from '../../Assets/Awards/Premios-Sadosky.png'
import Senado from '../../Assets/Awards/Senado_nacion.png'
import RedInnova from '../../Assets/Awards/redinnova.png'
import Forbes from '../../Assets/Awards/Forbes.png'
import Apertura from '../../Assets/Awards/apertura.png'
import Brando from '../../Assets/Awards/Brando.png'

/**
 * 
 * @description Componente de UI de la página de nuestro equipo
 */
export default function Team() {

    const [t] = useTranslation('global')

    const TeamCard = lazy(() => import('./Components/TeamCard'))

    return (
        <>

            <Helmet>
                <title>uSound Audiómetro | Nosotros</title>
            </Helmet>

            <Header />

            <main id='teamMain'>

                <section id='bannerOneTeam' className='col-9 mx-auto pb-5'>

                    <h1 id='ourTeam' className='text-center mb-5'>{t('team.our_team')}</h1>

                    <div className='d-flex flex-wrap justify-content-center'>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mb-4 mb-sm-0'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/ezequielescobar/"
                                    image={Eze}
                                    alt="Ezequiel Escobar"
                                    name="Ing. Ezequiel Escobar"
                                    role="Chief Executive Officer"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    image={Tavo}
                                    alt="Gustavo Ramos"
                                    name="Téc. Gustavo Ramos"
                                    role="Chief Customer Success Officer"
                                    subtitle="In memoriam"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4 mt-lg-0'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/ezequiel-andres-espi%C3%B1o-209a0022/"
                                    image={Zeque}
                                    alt="Ezequiel Espiño"
                                    name="Lic. Ezequiel Espiño"
                                    role="Chief Financial Officer"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/maraujo1/"
                                    image={Mati}
                                    alt="Matías Araujo"
                                    name="Ing. Matías Araujo"
                                    role="Chief Technology Officer"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/sanchez-patricia/"
                                    image={Pato}
                                    alt="Patricia Sanchez"
                                    name="Patricia Sanchez"
                                    role="Communication, Design & Marketing Manager"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/rafael-rodriguez-4738986b/"
                                    image={Rafa}
                                    alt="Rafael Rodriguez"
                                    name="Ing. Rafael Rodriguez"
                                    role="QA/QC Manager"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/cristian-david-armella-02989522b/"
                                    image={Cris}
                                    alt="Cristian Armella"
                                    name="Cristian Armella"
                                    role="Customer Success Specialist"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/pamela-casanova-829116208/"
                                    image={Pame}
                                    alt="Pamela Casanova"
                                    name="Pamela Casanova"
                                    role="Customer Success Specialist"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    image={Paola}
                                    alt="Paola Duarte"
                                    name="Paola Duarte"
                                    role="Customer Success Specialist Brazil"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/ignacio-isoardi-1125615a/"
                                    image={Nacho}
                                    alt="Ignacio Isoardi"
                                    name="Lic. Ignacio Isoardi"
                                    role="Administration Manager"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/alejandronewbrick/"
                                    image={Ale}
                                    alt="Alejandro Mendez"
                                    name="Ing. Alejandro Mendez"
                                    role="Android Team Lead"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/agusmariani25/"
                                    image={Agus}
                                    alt="Agustín Mariani"
                                    name="Ing. Agustín Mariani"
                                    role="Test Automation & Android Developer"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/pedrolipari/"
                                    image={Pedro}
                                    alt="Pedro Lipari"
                                    name="Ing. Pedro Lipari"
                                    role="Cloud Team Lead"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/adrian-vidaurre-254854b4"
                                    image={Adrian}
                                    alt="Anl. Adrián Vidaurre"
                                    name="Anl. Adrián Vidaurre"
                                    role="UX Designer & Android Developer"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/mariantoniatapia/"
                                    image={Mari}
                                    alt="María Tapia"
                                    name="Lic. María Tapia"
                                    role="UX Designer & Android Developer"
                                />
                            </Suspense>
                        </article>


                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="http://www.canasanmartin.com.ar/"
                                    image={Cana}
                                    alt="Juan San Martin"
                                    name="Juan San Martin"
                                    role="R&D Acoustics Specialist"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/maximiliano-ortiz-7664541a9/"
                                    image={MaxiOrtiz}
                                    alt="Ing. Maximiliano Ortiz"
                                    name="Ing. Maximiliano Ortiz"
                                    role="Audio DSP Engineer"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    image={Abraham}
                                    alt="Ing. Abraham Gonzáles"
                                    name="Ing. Abraham Gonzáles"
                                    role="Audiometers Specialist"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/nicolas-matias-murillo-aa6123230/"
                                    image={Nico}
                                    alt="Ing. Nicolás Murillo"
                                    name="Ing. Nicolás Murillo"
                                    role="Manufacturing Leader"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/eliana-ortiz-28683638/"
                                    image={Elu}
                                    alt="Eliana Ortiz"
                                    name="Lic. Eliana Ortiz"
                                    role="Social Media Manager"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    image={FrancoSmith}
                                    alt="Franco Smith"
                                    name="Franco Smith"
                                    role="Graphic Designer"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    image={Ceci}
                                    alt="Cecilia Slame"
                                    name="Lic. Cecilia Slame"
                                    role="Medical Support"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/vivianagalian/"
                                    image={Vivi}
                                    alt="Lic. Viviana Galián Alvarado"
                                    name="Lic. Viviana Galián Alvarado"
                                    role="Chief People & Culture Officer"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    image={LethiciaSantos}
                                    alt="Lethicia Santos"
                                    name="Lethicia Santos"
                                    role="Marketing and Communications Assistant"
                                />
                            </Suspense>
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    image={AlineAndrade}
                                    alt="Aline Alves Andrade Da Silva"
                                    name="Aline Alves Andrade Da Silva"
                                    role="Operations & Logistics Aassistant"
                                />
                            </Suspense>
                        </article>
                    </div>

                </section>

                <section id='bannerTwoTeam' className='col-12 mx-auto pt-4 pb-5 bg-white'>

                    <h1 id='commercial_team' className='text-center mb-5'>{t('team.commercial_team')}</h1>

                    <div className='d-flex flex-wrap justify-content-center'>
                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://bit.ly/WalterContactoWeb-uSound"
                                    image={Walter}
                                    alt="Walter Duarte"
                                    name="Walter Duarte"
                                    role="Country Manager Brazil"
                                />
                            </Suspense>
                            {/* <Flag code="BR" alt="Bandera de Brasil" className='mx-auto mt-1 flags' /> */}
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://www.linkedin.com/in/marcogonzalezl/"
                                    image={NaiaraGalieta}
                                    alt="Naiara Galieta"
                                    name="Naiara Galieta"
                                    role="Sales Executive Brazil"
                                />
                            </Suspense>
                            {/* <Flag code="BR" alt="Bandera de Brasil" className='mx-auto mt-1 flags' /> */}
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://bit.ly/MaxiContactoWeb-uSound"
                                    image={Maxi}
                                    alt="Maximiliano Gómez"
                                    name="Maximiliano Gómez"
                                    role="Sales Team Leader"
                                />
                            </Suspense>
                            {/* <img src={Arg} alt="Bandera de Argentina" className='mx-auto mt-1 flags' /> */}
                        </article>

                        <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://bit.ly/GuillermoContactoWeb-uSound"
                                    image={Guille}
                                    alt="Guillermo Chaile"
                                    name="Guillermo Chaile"
                                    role="Sales Executive"
                                />
                            </Suspense>
                            {/* <img src={Arg} alt="Bandera de Argentina" className='mx-auto mt-1 flags' /> */}
                        </article>

                        {/* <article className='col-12 col-sm-6 col-lg-4 d-flex flex-column mt-4'>
                            <Suspense fallback={<ProfileLoader />}>
                                <TeamCard
                                    link="https://bit.ly/SabrinaContactoWeb-uSound"
                                    image={LethiciaSantos}
                                    alt="Lethicia Santos"
                                    name="Lethicia Santos"
                                    role="Marketing and Communications Assistant"
                                />
                            </Suspense> */}
                            {/* <div className='d-flex justify-content-center mt-1 gap-2'>
                                <img src={Chi} alt="Bandera de Chile" className='flags' />
                                <img src={Col} alt="Bandera de Colombia" className='flags' />
                            </div> */}
                        {/* </article> */}
                        

                    </div>

                </section>

                <section id='bannerThreeTeam' className='pt-4 pb-5 px-5'>

                    <h1 id='investorsAndAdvisors' className='text-center text-white'>{t('team.investors_mentors')}</h1>

                    <div className='d-flex flex-wrap justify-content-center'>

                        <article className='col-12 col-md-6 col-lg-4 mt-4 p-3'>
                            <div className='d-flex'>
                                <a href='https://www.linkedin.com/in/juan-collado-a21837a/' target="_blank">
                                    <img src={JuanCollado} alt="Juan Collado" className='imgInvestors me-4' />
                                </a>
                                <div>
                                    <p className='text-white'>Juan Collado</p>
                                    <p className='text-white'>
                                        {t('team.collado_title')}
                                    </p>
                                </div>
                            </div>
                            <strong className='text-white d-block mt-3'>
                                {t('team.collado_desc')}
                            </strong>
                        </article>

                        <article className='col-12 col-md-6 col-lg-4 mt-4 p-3'>
                            <div className='d-flex'>
                                <a href='https://www.linkedin.com/in/axel-barcelo-71a4aa9/' target="_blank">
                                    <img src={AxelBarcelo} alt="Axel Barceló" className='imgInvestors me-4' />
                                </a>
                                <div>
                                    <p className='text-white'>Axel Barceló</p>
                                    <p className='text-white'>
                                        {t('team.barcelo_title')}
                                    </p>
                                </div>
                            </div>
                            <strong className='text-white d-block mt-3'>
                                {t('team.barcelo_desc')}
                            </strong>
                        </article>

                        <article className='col-12 col-md-6 col-lg-4 mt-4 p-3'>
                            <div className='d-flex'>
                                <a href='https://www.linkedin.com/in/mariafabianaoliver/' target="_blank">
                                    <img src={FabianaOliver} alt="Fabiana Oliver" className='imgInvestors me-4' />
                                </a>
                                <div>
                                    <p className='text-white'>Fabiana Oliver</p>
                                    <p className='text-white'>
                                        {t('team.oliver_title')}
                                    </p>
                                </div>
                            </div>
                            <strong className='text-white d-block mt-3'>
                                {t('team.oliver_desc')}
                            </strong>
                        </article>

                        <article className='col-12 col-md-6 col-lg-4 mt-4 p-3'>
                            <div className='d-flex'>
                                <a href='https://www.linkedin.com/in/claudia-berraondo-9b296b15/' target="_blank">
                                    <img src={ClaudiaBerraondo} alt="Claudia Berraondo" className='imgInvestors me-4' />
                                </a>
                                <div>
                                    <p className='text-white'>Claudia Berraondo</p>
                                    <p className='text-white'>
                                        {t('team.berraondo_title')}
                                    </p>
                                </div>
                            </div>
                            <strong className='text-white d-block mt-3'>
                                {t('team.berraondo_desc')}
                            </strong>
                        </article>

                        <article className='col-12 col-md-6 col-lg-4 mt-4 p-3'>
                            <div className='d-flex'>
                                <a href='https://www.linkedin.com/in/roth-cynthia-8b88a281/' target="_blank">
                                    <img src={CinthyaRoth} alt="Cinthya Roth" className='imgInvestors me-4' />
                                </a>
                                <div>
                                    <p className='text-white'>Cinthya Roth</p>
                                    <p className='text-white'>
                                        {t('team.roth_title')}
                                    </p>
                                </div>
                            </div>
                            <strong className='text-white d-block mt-3'>
                                {t('team.roth_desc')}
                            </strong>
                        </article>

                        <article className='col-12 col-md-6 col-lg-4 mt-4 p-3'>
                            <div className='d-flex'>
                                <a href='https://www.linkedin.com/in/gabrieldeboli/' target="_blank">
                                    <img src={GabrielDeboli} alt="Gabriel D' Eboli" className='imgInvestors me-4' />
                                </a>
                                <div>
                                    <p className='text-white'>Gabriel D' Eboli</p>
                                    <p className='text-white'>
                                        {t('team.deboli_title')}
                                    </p>
                                </div>
                            </div>
                            <strong className='text-white d-block mt-3'>
                                {t('team.deboli_desc')}
                            </strong>
                        </article>

                        <article className='col-12 col-md-6 col-lg-4 mt-4 p-3'>
                            <div className='d-flex'>
                                <a href='https://www.linkedin.com/in/pablobaldoma/' target="_blank">
                                    <img src={PabloBaldomaJones} alt="Pablo Baldomá Jones" className='imgInvestors me-4' />
                                </a>
                                <div>
                                    <p className='text-white'>Pablo Baldomá Jones</p>
                                    <p className='text-white'>
                                        {t('team.baldoma_title')}
                                    </p>
                                </div>
                            </div>
                            <strong className='text-white d-block mt-3'>
                                {t('team.baldoma_desc')}
                            </strong>
                        </article>

                    </div>

                </section>

                <section id='bannerFourTeam' className='p-5 d-flex flex-wrap justify-content-between'>
                    <div className='col-12 col-sm-6 col-lg-4 d-flex align-items-center justify-content-center'>
                        <img src={Wayra} alt="Logo de Wayra" id="wayraLogo" />
                    </div>
                    <div className='col-12 col-sm-6 col-lg-4 text-center'>
                        <img src={PuertoAsis} alt="Logo de Puerto Asís" id="puertoAsisLogo" />
                    </div>
                    <div className='col-12 col-sm-6 col-lg-4 d-flex align-items-center justify-content-center'>
                        <img src={Incubando} alt="Logo de Incubando Salud" className='imgAdvisors' />
                    </div>
                    <div className='col-12 col-sm-6 col-lg-4 text-center'>
                        <img src={Quarux} alt="Logo de Quarux" className='imgAdvisors' />
                    </div>
                    <div className='col-12 col-sm-6 col-lg-4 text-center'>
                        <img src={Lphub} alt="Logo de LP Hub" className='imgAdvisors' />
                    </div>
                    <div className='col-12 col-sm-6 col-lg-4 text-center'>
                        <img src={Mvd500} alt="Logo de 500 MVD" className='imgAdvisors' />
                    </div>
                </section>

                <section id='bannerFiveTeam' className='p-5'>

                    <h1 id='awards' className='text-center'>{t('team.awards')}</h1>

                    <div className='mt-4 px-5'>

                        <div className='d-flex flex-wrap justify-content-around align-items-center'>
                            <div className='text-center col-12 col-sm-6 col-lg-3'>
                                <img id='logoUSA' src={UsaEmbassy} alt="Embajada de Estados Unidos en Argentina" />
                            </div>
                            <div className='text-center col-12 col-sm-6 col-lg-3 mt-5 mt-sm-0'>
                                <img src={Bid} alt="Banco Interamericano de Desarrollo" className='imgAwards' />
                            </div>
                            <div className='text-center col-12 col-sm-6 col-lg-3 mt-5 mt-lg-0'>
                                <img src={Innovadores} alt="MIT Innovadores menores de 35" className='imgAwards' />
                            </div>
                            <div className='text-center col-12 col-sm-6 col-lg-3 mt-5 mt-lg-0'>
                                <img src={MassChallenge} alt="Mass Challenge" className='imgAwards' />
                            </div>
                        </div>

                        <div className='d-flex flex-wrap justify-content-around align-items-center'>
                            <div className='text-center col-12 col-sm-6 col-lg-3 mt-5 mt-sm-0'>
                                <img src={ChinaHealthSummit} alt="China Health Summit" className='imgAwardsTwo' />
                            </div>
                            <div className='text-center col-12 col-sm-6 col-lg-3 my-5'>
                                <img src={M2D2} alt="Massachusetts Medical Device Development Center" className='imgAwardsTwo' />
                            </div>
                            <div className='text-center col-12 col-sm-6 col-lg-3 mb-5 mb-lg-0'>
                                <img src={Naves} alt="NAVES" className='imgAwardsTwo' />
                            </div>
                            <div className='text-center col-12 col-sm-6 col-lg-3'>
                                <img id='imagineCup' src={ImagineCup} alt="Imagine Cup" />
                            </div>
                        </div>

                        <div className='d-flex flex-wrap justify-content-around align-items-center'>
                            <div className='text-center col-12 col-sm-6 col-lg-3 my-5'>
                                <img src={PremiosSadosky} alt="Premios Sadosky" className='imgAwardsTwo' />
                            </div>
                            <div className='text-center col-12 col-sm-6 col-lg-3'>
                                <img src={Senado} alt="Honorable Senado de la Nación Argentina" />
                            </div>
                            <div className='text-center col-12 col-sm-6 col-lg-3 my-4'>
                                <img src={RedInnova} alt="Red Innova Challenge" className='imgAwardsTwo' />
                            </div>
                            <div className='text-center col-12 col-sm-6 col-lg-3 mt-4'>
                                <img id="forbes" src={Forbes} alt="Forbes" />
                            </div>
                        </div>

                        <div className='d-flex flex-wrap justify-content-around align-items-center'>
                            <div className='text-center col-12 col-sm-6 col-lg-3 my-5 my-lg-0'>
                                <img src={Apertura} alt="Apertura" className='imgAwardsThree' />
                            </div>
                            <div className='text-center col-12 col-sm-6 col-lg-3'>
                                <img src={Brando} alt="Brando" className='imgAwardsThree' />
                            </div>
                        </div>

                    </div>

                </section>

            </main>

            <Footer />

        </>
    )
}