import axios from 'axios'

import { urls } from '../Utilities/Http'

/**
 * 
 * @description Pide al backend las respuestas ingresadas por el usuario en form de Typeform
 * y las agrega a la capa de datos de Tag Manager
 * @param {string} responseId - ID de la respuesta brindado por Typeform 
 * @param {string} formId - ID del formulario de Typeform que acaba de ser enviado
 */
export const ConnectTypeformTagManager = ({ responseId }, formId) => {
    let formData = {
        response_id: responseId,
        form_id: formId
    }
    setTimeout(async () => {
        try {
            const { data } = await axios.post(urls.typeformUrl, formData)
            window.dataLayer.push(data)
            window.dataLayer.push({ 'event': 'formulario_submit' })
            console.log(window.dataLayer)
        } catch (err) {
            console.log(err)
        }
    }, 1000);
}