import * as bootstrap from 'bootstrap'

/**
 * @description Mostrar modal
 * @param {string} id - ID del modal a mostrar
 * @param {Object} options - Objeto de configuracion opcional
 */
export const showModal = (id, options = null) => {
    let element = document.getElementById(id)
    let modal = new bootstrap.Modal(element, options)
    modal.toggle()
}

/**
 * @description Ocultar modal
 * @param {string} id - ID del modal a ocultar
 */
export const hideModal = id => {
    let element = document.getElementById(id)
    let modal = bootstrap.Modal.getInstance(element)
    modal.hide()
}

/**
 * @description Mostrar Offcanvas
 * @param {string} id - ID del offcanvas a mostrar
 */
export const showOffcanvas = id => {
    let element = document.getElementById(id)
    let off = new bootstrap.Offcanvas(element)
    off.show()
}

/**
 * @description Ocultar Offcanvas
 * @param {string} id - ID del offcanvas a ocultar
 */
export const hideOffcanvas = id => {
    let element = document.getElementById(id)
    let off = bootstrap.Offcanvas.getInstance(element)
    off?.hide()
}