import { useTranslation } from 'react-i18next'

import collageretspl from '../../../Assets/Home/collage-retspl-02-02.svg'
import UCASAL from '../../../Assets/Logos/UCASAL.png'
import LogoHorizontal from '../../../Assets/Logos/logo_horizontal_usound.svg'

export default function Retspl() {

    const commonStyles = {
        fontSize: "1rem",
        padding: "7px 40px",
        border: "none",
        cursor: "pointer",
    }

    const [t, i18n] = useTranslation('global')

    return (
       
        <section id="retspl" className='pb-5'>
             
            <div className='row justify-content-center'>
                <div className='col-10 col-lg-8'>
                    <h4 className='fw-bold text-center fs-1 pt-5 pb-3'>
                        {t('home.retspl.title')}
                    </h4>
                    <div className='row justify-content-center pb-4'>
                        <div className='col-12 col-lg-10'>
                            <div className='row justify-content-center'>
                                <div className='col-5'>
                                    <img id='UCASAL' className='w-100' src={UCASAL} alt="retspl" />
                                </div>
                                <div className='col-5'>
                                    <img id='LogoHorizontal' className='w-100 p-2' src={LogoHorizontal} alt="retspl" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card" style={{borderRadius: '30px', background: 'linear-gradient(#01b2fc,#1ce8b8)', boxShadow: '0 0 10px rgb(199, 198, 198)'}}>
                        <div className="card-body text-white text-center text-lg-start">
                            <p className="pt-2">
                                {t('home.retspl.description_1')}
                            </p>
                            <p className="pt-2">
                                {t('home.retspl.description_2')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row justify-content-center pt-5'>
                <div className='col-10 col-lg-4'>
                    <img id='collageretspl' src={collageretspl} alt="retspl" />
                </div>
                <div className='col-10 col-lg-4 d-flex align-items-center'>
                    <button
                        type='button'
                        onClick={() => window.open(t('home.retspl.link_button'), '_blank')}
                        className="usxsBtn"
                        style={{
                            backgroundColor: "#18F0C9"
                        }}
                    >
                        {t('home.retspl.button')}
                    </button>
                </div>
            </div>
        </section>
    )
}