import { Widget } from '@typeform/embed-react'

import SetUtmParams from "../../Utilities/SetUtmParams";
import { ConnectTypeformTagManager } from '../../Utilities/ConnectTypeformTagManager';

/**
 * 
 * @description Componente de Typeform - Formulario inclustado como Widget
 * @param {string} formId - ID del formulario de Typeform que se incrusta
 */
export default function TfWidget({
    formId,
    utmContent
}) {
    return (
        <Widget
            id={formId}
            className='form'
            inlineOnMobile={true}
            medium="snippet"
            disableAutoFocus
            hidden={{
                utm_source: SetUtmParams(window.location.href, 'utm_source'),
                utm_medium: SetUtmParams(window.location.href, 'utm_medium'),
                utm_campaign: SetUtmParams(window.location.href, 'utm_campaign'),
                utm_term: SetUtmParams(window.location.href, 'utm_term'),
                utm_content: { utmContent }
            }}
            onSubmit={responseId => ConnectTypeformTagManager(responseId, formId)}
        />
    )
}