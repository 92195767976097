import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import Header from '../../AppComponents/Layout/Header'
import Footer from '../../AppComponents/Layout/Footer'

// FOTOS DE PROFESIONALES
import VictorioStok from '../../Assets/Professionals/victorio_stok.png'
import CeciliaSlame from '../../Assets/Professionals/ceci.png'
import LauraDominguez from '../../Assets/Professionals/laura.png'
import NicolasGuerrero from '../../Assets/Professionals/nicolas.png'
import BenjaminRojas from '../../Assets/Professionals/benjamin.png'
import JoseGregorioLedezma from '../../Assets/Professionals/jose.png'

// BANDERAS
import Arg from '../../Assets/svg/arg.svg'
import Chi from '../../Assets/svg/chi.svg'

// LOGOS DE CONVENIOS Y ACUERDOS
import InstitutoStok from '../../Assets/Logos/Instituto-Stok.png'
import FundacionIntegrar from '../../Assets/Logos/Integrar.png'
import UNJU from '../../Assets/Logos/UNJU.png'
import USPT from '../../Assets/Logos/USPT.png'
import UCSE from '../../Assets/Logos/UCSE.png'
import UCASAL from '../../Assets/Logos/UCASAL.png'
import MinisterioSalud from '../../Assets/Logos/Ministerio-Salud.png'

/**
 * 
 * @description Componente de UI de la página de profesionales
 */
export default function Professionals() {

    const [t] = useTranslation('global')

    return (

        <>

            <Helmet>
                <title>uSound Audiómetro | Profesionales</title>
            </Helmet>

            <Header />

            <main id='professionalsMain'>

                <h1 className='text-center'>{t('professionals.title')}</h1>

                <section className='d-flex flex-wrap'>
                    <article className='text-center col-12 col-sm-6'>
                        <img src={VictorioStok} alt="Victorio Stok" className='profImg mt-4 mb-3' />
                        <p><strong>Victorio Stok</strong></p>
                        <p className='text-muted'>Otorrinolaringólogo</p>
                        <p className='text-muted'>MP: 4264</p>
                        <p className='col-6 d-block mx-auto'>
                            {t('professionals.stok_desc')}
                        </p>
                    </article>
                    <article className='text-center col-12 col-sm-6'>
                        <img src={CeciliaSlame} alt="Cecilia Slame" className='profImg mt-4 mb-3' />
                        <p><strong>Cecilia Slame</strong></p>
                        <p className='text-muted'>Lic. en Fonoaudiología</p>
                        <p className='text-muted'>MP: 1291</p>
                        <p className='col-6 d-block mx-auto'>
                            {t('professionals.slame_desc')}
                        </p>
                    </article>
                </section>

                <h1 className='text-center mt-5'>{t('professionals.brand_ambassadors')}</h1>

                <section className='d-flex flex-wrap px-5'>
                    <article className='text-center col-12 col-sm-6 col-md-3'>
                        <img src={LauraDominguez} alt="Lic. Laura Domínguez" className='ambassImg mt-4 mb-3' />
                        <p className='mb-1'><strong>Lic. Laura Domínguez</strong></p>
                        <p className='col-6 d-block mx-auto'>
                            <img src={Arg} alt="Bandera de Argentina" className='flags' />
                        </p>
                    </article>
                    <article className='text-center col-12 col-sm-6 col-md-3'>
                        <img src={NicolasGuerrero} alt="Fgo. Nicolás Guerrero Kramm" className='ambassImg mt-4 mb-3' />
                        <p className='mb-1'><strong>Fgo. Nicolás Guerrero Kramm</strong></p>
                        <p className='col-6 d-block mx-auto'>
                            <img src={Chi} alt="Bandera de Chile" className='flags' />
                        </p>
                    </article>
                    <article className='text-center col-12 col-sm-6 col-md-3'>
                        <img src={BenjaminRojas} alt="Fgo. Benjamín Rojas" className='ambassImg mt-4 mb-3' />
                        <p className='mb-1'><strong>Fgo. Benjamín Rojas</strong></p>
                        <p className='col-6 d-block mx-auto'>
                            <img src={Chi} alt="Bandera de Chile" className='flags' />
                        </p>
                    </article>
                    <article className='text-center col-12 col-sm-6 col-md-3'>
                        <img src={JoseGregorioLedezma} alt="Dr. José Gregorio Ledezma" className='ambassImg mt-4 mb-3' />
                        <p className='mb-1'><strong>Dr. José Gregorio Ledezma</strong></p>
                        <p className='col-6 d-block mx-auto'>
                            <img src={Chi} alt="Bandera de Chile" className='flags' />
                        </p>
                    </article>
                </section>

                <h1 className='text-center mt-5'>{t('professionals.conventions_agreements')}</h1>

                <section className='d-flex flex-wrap px-5 justify-content-center mt-4'>
                    <article className='text-center col-12 col-md-4 mb-4 mb-md-0'>
                        <img src={InstitutoStok} alt="Instituto Stok" className='accordImg mb-3' />
                        <p className='fs-4'><strong>Instituto Stok S. C.</strong></p>
                        <p className='fs-5'>Instituto de ORL.</p>
                        <p className='fs-5'>San Miguel de Tucumán</p>
                    </article>
                    <article className='text-center col-12 col-md-4'>
                        <img src={FundacionIntegrar} alt="Fundación INTEGRAR" className='accordImg mb-1' />
                        <p className='fs-4'><strong>Fundación I.N.T.E.G.R.A.R.</strong></p>
                        <p className='fs-5'>Instituto de ORL.</p>
                        <p className='fs-5'>San Miguel de Tucumán</p>
                    </article>
                </section>

                <section className='d-flex align-items-end flex-wrap px-5 justify-content-center mt-4'>
                    <article className='text-center col-12 col-lg-4'>
                        <img src={USPT} alt="USPT" className='accordWitherImg mb-2' />
                        <p className='fs-4'><strong>USPT</strong></p>
                        <p className='fs-5'>Facultad de Medicina</p>
                    </article>
                    <article className='text-center col-12 col-lg-4'>
                        <img src={UNJU} alt="UNJU" className='accordImg mb-3' />
                        <p className='fs-4'><strong>UNJU</strong></p>
                        <p className='fs-5'>Universidad Nacional de Jujuy</p>
                    </article>
                </section>
                <section className='d-flex align-items-end flex-wrap px-5 justify-content-center mt-4'>
                    <article className='text-center col-12 col-lg-4'>
                        <img src={UCASAL} alt="UCASAL" className='accordWitherImg mb-2' />
                        <p className='fs-4'><strong>UCASAL</strong></p>
                        <p className='fs-5'>Salta</p>
                    </article>
                    <article className='text-center col-12 col-lg-4'>
                        <img src={UCSE} alt="UCSE" className='accordImg' />
                        <p className='fs-4'><strong>UCSE</strong></p>
                        <p className='fs-5'>San Salvador de Jujuy</p>
                    </article>
                </section>

                <section className='d-flex px-5 justify-content-center'>
                    
                    <article className='text-center col-6'>
                        <img id='minImg' src={MinisterioSalud} alt="Ministerio de Salud" className='accordWitherImg mb-3' />
                        <p className='fs-4'><strong>Ministerio de Salud</strong></p>
                        <p className='fs-5'>Jujuy</p>
                    </article>
                </section>

            </main>

            <Footer />

        </>

    )
}