import { useState } from 'react'
import { PopupButton } from 'react-calendly'

import TfModal from '../CTA/TfModal'
import NfModal from '../CTA/NfModal'
import SetUtmParams from '../../../Utilities/SetUtmParams'

import Logo from '../../../Assets/Logos/logo_usound_color.svg'
import LogoCyberdays from '../../../Assets/Logos/logo_cyberdays_usound.svg' //logo cyberdays


export default function Header() {

    const commonStyles = {
        fontSize: ".6rem",
        padding: "7px 40px",
        border: "none",
        cursor: "pointer",
    }

    const [show, setShow] = useState(false)

    return (
        <div
            id='landing_header'
            className='d-flex align-items-center px-3 px-md-5 shadow-sm justify-content-between'
        >

            <img
                src={Logo}
                alt="Logo uSound"
                id='landing_logo'
                className='cursor-pointer'
                onClick={() => {
                    let element = document.getElementById('body')
                    element.scrollIntoView({ behavior: 'smooth' })
                }}
            />


            <div className='d-none d-lg-flex gap-4'>
                <TfModal
                    form_id="CH7orxE7"
                    utm_content="landing_typeform_contact_1"
                    styles={{
                        ...commonStyles,
                        backgroundColor: "#00B0FF"
                    }}
                    text="CONTÁCTANOS"
                />
                {/* <NfModal
                    text="CONTÁCTANOS"
                    form_url="https://form.nativeforms.com/QNOhDUL1jZmYnM1ZWaR1Db"
                    utm_content="landing_native_contact_1"
                    styles={{
                        ...commonStyles,
                        backgroundColor: "#00B0FF"
                    }}
                    show={show}
                    setShow={setShow}
                /> */}
                <TfModal
                    form_id="pKcQfhRu"
                    utm_content="landing_typeform_demo_1"
                    styles={{
                        ...commonStyles,
                        backgroundColor: "#18F0C9"
                    }}
                    text="AGENDA UNA DEMO GRATIS"
                />
                {/* <PopupButton
                    url="https://calendly.com/usoundaudiometro/demo"
                    styles={{
                        ...commonStyles,
                        backgroundColor: "#18F0C9"
                    }}
                    rootElement={document.getElementById("root")}
                    text="AGENDA UNA DEMO GRATIS"
                    textColor="#ffffff"
                    color="#18F0C9"
                    utm={{
                        utmSource: SetUtmParams(window.location.href, 'utm_source'),
                        utmMedium: SetUtmParams(window.location.href, 'utm_medium'),
                        utmCampaign: SetUtmParams(window.location.href, 'utm_campaign'),
                        utmTerm: SetUtmParams(window.location.href, 'utm_term'),
                        utmContent: 'landing_calendly_demo_1'
                    }}
                /> */}
            </div>

        </div>
    )
}