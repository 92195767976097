import { useState, useEffect } from "react";

const Counter = ({ before, start, end, after }) => {
    const [number, setNumber] = useState(start)

    useEffect(() => {
        const interval = setInterval(() => {
            number < end && setNumber(number => number + 2)
        }, 10);

        return () => {
            clearInterval(interval);
        };
    }, [number]);

    return <span>{before + number + after}</span>
}

export default Counter