/**
 * 
 * @description Determina el idioma por defecto del sitio en base
 * al idioma configurado en el navegador
 * @returns {string} Idioma del navegador
 */
export const getBrowserLanguage = () => {
  if (navigator.language.includes("es")) {
    return "es";
  }
  return "en";
};
