/**
 * 
 * @param {string} url - URL base de los parámetros a configurar
 * @param {string} param - Nombre del parámetro a configurar
 * @returns {string} Valor del parámetro
 */
const SetUtmParams = (url, param) => {
    let result
    let params = url.split('?')[1]?.split('&') || []
    let keys = params.map(p => p.split('=')[0])
    if (param === 'utm_source' && !keys.includes(params)) result = 'uSoundWeb'
    if (param === 'utm_medium' && !keys.includes(params)) result = 'Web'
    if (param === 'utm_campaign' && !keys.includes(params)) result = 'None'
    if (param === 'utm_term' && !keys.includes(params)) result = 'None'
    if (keys.includes(param)) {
        params.forEach(p => {
            let [key, value] = p.split('=')
            if (key === param) {
                result = value
            }
        })
    }
    return result
}

export default SetUtmParams