export default function FeaturesDiv({
    color
}) {
    return (
        <div
            className="text-center features_div"
            style={{
                color
            }}
        >
            <p>
                All in one
            </p>
            <p>
                Portable y ultraliviano
            </p>
            <p>
                Sin cabina, con transductor aéreo
                (tecnología ANC) y óseo
            </p>
            <p>
                Reportes automáticos y personalizados
            </p>
            <p>
                Calibración remota al instante
            </p>
            <p>
                Único en LATAM
            </p>
        </div>
    )
}