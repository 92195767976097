export default function Zigzag({
    color
}) {
    return (
        <svg id="Grupo_1011" data-name="Grupo 1011" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="324" height="22.411" viewBox="0 0 324 22.411">
            <defs>
                <clipPath id="clipPath">
                    <rect id="Rectángulo_1274" data-name="Rectángulo 1274" width="324" height="22.411" fill="none" />
                </clipPath>
                <clipPath id="clipPath-2">
                    <rect id="Rectángulo_1273" data-name="Rectángulo 1273" width="324.004" height="22.411" fill="none" />
                </clipPath>
                <clipPath id="clipPath-3">
                    <rect id="Rectángulo_1272" data-name="Rectángulo 1272" width="324" height="17.698" fill="none" />
                </clipPath>
            </defs>
            <g id="Grupo_6" data-name="Grupo 6" clipPath="url(#clipPath)">
                <g id="Grupo_5" data-name="Grupo 5">
                    <g id="Grupo_4" data-name="Grupo 4" clipPath="url(#clipPath-2)">
                        <g id="Grupo_3" data-name="Grupo 3" transform="translate(0 4.712)" opacity="0.51">
                            <g id="Grupo_2" data-name="Grupo 2">
                                <g id="Grupo_1" data-name="Grupo 1" clipPath="url(#clipPath-3)">
                                    <path id="Trazado_198" data-name="Trazado 198" d="M4.649,18.171c7.747.291,13.148-4.086,19.751-7.2,2.54-1.188,4.841-.411,7.164,1.008,4.124,2.592,8.269,5.11,13.282,5.222,4.6.1,9.069-2.39,12.856-4.766,2.42-1.5,5.2-2.846,7.694-1.666,2.7,1.292,5.005,3.377,7.664,4.8a23.684,23.684,0,0,0,13.08,2.7c6.155-.486,6.208-10.122,0-9.651a15.892,15.892,0,0,1-10.04-2.4c-2.719-1.666-5.095-3.466-8.038-4.684C64.358-.034,59.734.593,56.2,2.267,52.465,4.03,49.119,7.279,44.846,7.6c-3.735.247-7.56-3.638-10.727-5.177A16.1,16.1,0,0,0,23.093,1.333C16.489,2.946,11.82,8.848,4.656,8.571c-6.215-.284-6.2,9.353-.007,9.6" transform="translate(-0.002 -0.63)" fill={color} />
                                    <path id="Trazado_199" data-name="Trazado 199" d="M15.087,18.171c7.747.291,13.148-4.086,19.751-7.2,2.54-1.188,4.841-.411,7.164,1.008,4.124,2.592,8.269,5.11,13.282,5.222,4.6.1,9.069-2.39,12.856-4.766,2.42-1.5,5.2-2.846,7.694-1.666,2.7,1.292,5.005,3.377,7.664,4.8a23.684,23.684,0,0,0,13.08,2.7c6.155-.486,6.208-10.122,0-9.651a15.892,15.892,0,0,1-10.04-2.4C83.82,4.545,81.444,2.745,78.5,1.527,74.8-.034,70.172.593,66.639,2.267,62.9,4.03,59.557,7.279,55.284,7.6c-3.735.247-7.56-3.638-10.727-5.177A16.1,16.1,0,0,0,33.531,1.333c-6.6,1.614-11.272,7.515-18.436,7.239-6.215-.284-6.2,9.353-.007,9.6" transform="translate(67.533 -0.63)" fill={color} />
                                    <path id="Trazado_200" data-name="Trazado 200" d="M25.432,18.171c7.747.291,13.148-4.086,19.751-7.2,2.54-1.188,4.841-.411,7.164,1.008,4.124,2.592,8.269,5.11,13.282,5.222,4.6.1,9.069-2.39,12.856-4.766,2.42-1.5,5.2-2.846,7.694-1.666,2.7,1.292,5.005,3.377,7.664,4.8a23.684,23.684,0,0,0,13.08,2.7c6.155-.486,6.208-10.122,0-9.651a15.892,15.892,0,0,1-10.04-2.4c-2.719-1.666-5.095-3.466-8.038-4.684-3.705-1.561-8.329-.934-11.863.74C73.248,4.03,69.9,7.279,65.629,7.6c-3.735.247-7.56-3.638-10.727-5.177A16.1,16.1,0,0,0,43.876,1.333C37.272,2.946,32.6,8.848,25.439,8.571c-6.215-.284-6.2,9.353-.007,9.6" transform="translate(134.467 -0.63)" fill={color} />
                                    <path id="Trazado_201" data-name="Trazado 201" d="M35.87,18.171c7.747.291,13.148-4.086,19.751-7.2,2.54-1.188,4.841-.411,7.164,1.008,4.124,2.592,8.27,5.11,13.282,5.222,4.6.1,9.069-2.39,12.856-4.766,2.42-1.5,5.2-2.846,7.694-1.666,2.7,1.292,5.005,3.377,7.664,4.8a23.684,23.684,0,0,0,13.08,2.7c6.155-.486,6.208-10.122,0-9.651a15.892,15.892,0,0,1-10.04-2.4c-2.719-1.666-5.095-3.466-8.038-4.684-3.705-1.561-8.329-.934-11.863.74C83.686,4.03,80.34,7.279,76.067,7.6c-3.735.247-7.56-3.638-10.727-5.177A16.1,16.1,0,0,0,54.314,1.333c-6.6,1.614-11.272,7.515-18.436,7.239-6.215-.284-6.2,9.353-.007,9.6" transform="translate(202.003 -0.63)" fill={color} />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    )
}