import { Popover } from '@typeform/embed-react'
import { useTranslation } from 'react-i18next'

import SetUtmParams from '../../Utilities/SetUtmParams'
import { ConnectTypeformTagManager } from '../../Utilities/ConnectTypeformTagManager'

/**
 * 
 * @description Componente de Typeform - Botón que abre un form en un Popover tipo chat
 * @param {string} formId - ID del formulario de Typeform que llama el botón
 */
export default function TfPopover({
    formId,
    utmContent
}) {

    const [t, i18n] = useTranslation('global')

    return (
        <Popover
            id={formId}
            customIcon="https://images.typeform.com/images/nQxXNsezb5WK"
            buttonColor="#EBEBEB"
            tooltip={t('home.typeform.popover')}
            medium="snippet"
            chat={true}
            className='typeformChat'
            hidden={{
                utm_source: SetUtmParams(window.location.href, 'utm_source'),
                utm_medium: SetUtmParams(window.location.href, 'utm_medium'),
                utm_campaign: SetUtmParams(window.location.href, 'utm_campaign'),
                utm_term: SetUtmParams(window.location.href, 'utm_term'),
                utm_content: { utmContent }
            }}
            onSubmit={responseId => ConnectTypeformTagManager(responseId, formId)}
        />
    )
}