import { Helmet } from "react-helmet";

import Banner from "./Components/Banner";
import Header from "./Components/Header";
import Footer from '../../AppComponents/Layout/Footer'
import Estudios from "./Components/Estudios";
import Registros from '../Home/Sections/Registros'
import Dark from "./Components/Dark";

export default function Landing() {
    return (
        <div id="landing_container">

            <Helmet>
                <title>uSound Audiómetro</title>
            </Helmet>

            <Header />

            <Banner />

            <Estudios />

            <Registros landing="true" />

            <Dark />

            <Footer />

        </div>
    )
}