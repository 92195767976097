import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Header from '../../AppComponents/Layout/Header'
import Banner from './Sections/Banner'
import Mapa from './Sections/Mapa'
// import Logos from './Sections/Logos'
import Caracteristicas from './Sections/Caracteristicas'
import Estudios from './Sections/Estudios'
import Herramientas from './Sections/Herramientas'
import Hardware from './Sections/Hardware'
import Casos from './Sections/Casos'
import Registros from './Sections/Registros'
import Contacto from './Sections/Contacto'
import Footer from '../../AppComponents/Layout/Footer'

import { getBrowserLanguage } from '../../Utilities/Language'

import Hero from './Sections/Hero'
import Anc from './Sections/Anc'
import Evidencia from './Sections/Evidencia'
import Referencia from './Sections/Referencia'
import Retspl from './Sections/Retspl'

/**
 * 
 * @description Componente de UI de la página principal
 */
export default function Home() {

    const navigate = useNavigate()

    /**
     * @description Hook de efecto que detecta si el sitio se abre como campaña o no
     * Si es así, abre el modal del formulario de Typeform
     */
    // useEffect(() => {
    //     if (window.location.pathname === '/') {
    //         navigate('/' + getBrowserLanguage())
    //     }
    //     let fullUrl = window.location.href
    //     if (fullUrl.split('#')[1]?.split('?').includes('ModalAudiometer')) {
    //         document.getElementById('typeformButtonClick').click()
    //     }
    // }, [])

    return (

        <>

            <Helmet>
                <title>uSound Audiómetro | Inicio</title>
            </Helmet>

            <Header />

            <main id='homeMain'>

                <Banner />

                <Mapa />

                {/* <Logos /> */}

                <Hero />

                <Anc />

                <Evidencia />

                <Referencia />

                <Retspl/>

                <Caracteristicas />

                <Estudios />

                <section id='second_container'>

                    <Herramientas />

                    <Hardware />

                </section>

                <Registros />

                <Casos />

                <Contacto />

            </main>

            <Footer />

        </>

    )

}