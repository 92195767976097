import { useTranslation } from "react-i18next";
import { Routes, Route } from "react-router-dom";

import PageNotFound from "./Pages/Error/PageNotFound";
import Home from "./Pages/Home/Home";
import Landing from "./Pages/Landing/Landing";
import Professionals from "./Pages/Professionals/Professionals";
import AudiometerPasswordRecovery from "./Pages/AudiometerPasswordRecovery/AudiometerPasswordRecovery";
import Team from "./Pages/Team/Team";
import ScrollToTop from './Utilities/ScrollToTop';

/**
 * 
 * @description Páginas del sitio web
 */
export default function Router() {
  const [t, i18n] = useTranslation("global");

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path={"/"}
          element={<Home />}
        />
        <Route
          path={
            i18n.language === "es" ?
              "/es" :
              i18n.language === "pt" ?
                "/pt" :
                "/en"
          }
          element={<Home />}
        />
        <Route
          path={
            i18n.language === "es" ?
              "/es/profesionales" :
              i18n.language === "pt" ?
                "/pt/profissionais" :
                "/en/professionals"
          }
          element={<Professionals />}
        />
        <Route
          path={
            i18n.language === "es" ?
              "/es/nuestro-equipo" :
              i18n.language === "pt" ?
                "/pt/nos" :
                "/en/about-us"
          }
          element={<Team />}
        />
        <Route
          path="/audiometro"
          element={<Landing />}
        />
        <Route
          path="/user-password-recovery"
          element={<AudiometerPasswordRecovery />}
        />
        <Route
          path="*"
          element={<PageNotFound />}
        />
      </Routes>
    </>
  );
}

