import { useTranslation } from "react-i18next";

import TfWidget from "../../../AppComponents/Typeform/TfWidget";

export default function Contacto() {

    const [t] = useTranslation('global')

    return (
        <section id="contacto" className="pt-5 pb-5">

            <h3 className="text-center text-white">{t('home.contacto.title')}</h3>
            <p className="text-center text-secondary">
                {t('home.contacto.subtitle')}
            </p>

            <div className="p-2 mx-3 mx-lg-auto col-lg-10 text-center text-white form_header">
                {t('home.contacto.disclaimer')}
            </div>

            <article id="formContainer" className="border border-white p-2 mx-3 mx-lg-auto col-lg-10 mb-4 bg-transparent">
                <TfWidget
                    formId={t('home.typeform.widget_id')}
                    utmContent="Widget"
                />
            </article>

        </section>
    )
}