import { useTranslation } from 'react-i18next'

import Misc from '../../../Assets/Home/misc.svg'

export default function Evidencia() {

    const [t, i18n] = useTranslation('global')

    return (
        <section id="evidencia" className='bg-light pb-5'>
            <img src={Misc} alt="" className='miscDown' />
            
            <h4 className='text-center text-info fw-bold fs-1 pt-5 pb-5'>
                {t('home.evidencia.title')}
            </h4>

            <div className="row justify-content-center">
                <div className="col-10 col-md-6 col-lg-5 mb-4">
                    <div className="card" style={{borderRadius: '10px', boxShadow: '0 0 10px rgb(199, 198, 198)'}}>
                        <div className="card-body">
                            <h5 className="card-title text-center text-lg-start text-info fw-bold pt-2 pb-2">{t('home.evidencia.items.title_1')}</h5>
                            <div className="card-text">
                                <div className="mb-3">
                                    <span className="fw-bold">{t('home.evidencia.items.subtitle_1_1')} </span>
                                    <span>{t('home.evidencia.items.description_1_1')}</span>
                                </div>
                                
                                <div className="mb-3">
                                    <span className="fw-bold">{t('home.evidencia.items.subtitle_1_2')} </span>
                                    <span className="fst-italic">{t('home.evidencia.items.description_1_2')}</span>
                                </div>
                                <div>
                                    <span className="fw-bold">{t('home.evidencia.items.subtitle_1_3')} </span>
                                    <span>{t('home.evidencia.items.description_1_3')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-10 col-md-6 col-lg-5 mb-4">
                    <div className="card" style={{borderRadius: '10px', boxShadow: '0 0 10px rgb(199, 198, 198)'}}>
                        <div className="card-body">
                            <h5 className="card-title text-center text-lg-start text-info fw-bold pt-2 pb-2">{t('home.evidencia.items.title_2')}</h5>
                            <div className="card-text">
                                <div className="mb-3">
                                    <span className="fw-bold">{t('home.evidencia.items.subtitle_2_1')} </span>
                                    <span>{t('home.evidencia.items.description_2_1')}</span>
                                </div>
                                
                                <div className="mb-3">
                                    <span className="fw-bold">{t('home.evidencia.items.subtitle_2_2')} </span>
                                    <span className="fst-italic">{t('home.evidencia.items.description_2_2')}</span>
                                </div>
                                <div>
                                    <span className="fw-bold">{t('home.evidencia.items.subtitle_3_3')} </span>
                                    <span>{t('home.evidencia.items.description_3_3')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-10 col-md-6 col-lg-5 mb-4">
                    <div className="card" style={{borderRadius: '10px', boxShadow: '0 0 10px rgb(199, 198, 198)'}}>
                        <div className="card-body">
                            <h5 className="card-title text-center text-lg-start text-info fw-bold pt-2 pb-2">{t('home.evidencia.items.title_3')}</h5>
                            <div className="card-text">
                                <div className="mb-3">
                                    <span className="fw-bold">{t('home.evidencia.items.subtitle_3_1')} </span>
                                    <span>{t('home.evidencia.items.description_3_1')}</span>
                                </div>
                                
                                <div className="mb-3">
                                    <span className="fw-bold">{t('home.evidencia.items.subtitle_3_2')} </span>
                                    <span className="fst-italic">{t('home.evidencia.items.description_3_2')}</span>
                                </div>
                                <div>
                                    <span className="fw-bold">{t('home.evidencia.items.subtitle_3_3')} </span>
                                    <span>{t('home.evidencia.items.description_3_3')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-10 col-md-6 col-lg-5 mb-4">
                    <div className="card" style={{borderRadius: '10px', boxShadow: '0 0 10px rgb(199, 198, 198)'}}>
                        <div className="card-body">
                        <h5 className="card-title text-center text-lg-start text-info fw-bold pt-2 pb-2">{t('home.evidencia.items.title_4')}</h5>
                            <div className="card-text">
                            <ul className="fst-italic list-unstyled ps-4">
                                <li>• {t('home.evidencia.items.description_4_1')}</li>
                                <li>• {t('home.evidencia.items.description_4_2')}</li>
                                <li>• {t('home.evidencia.items.description_4_3')}</li>
                                <li>• {t('home.evidencia.items.description_4_4')}</li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}