import { PopupButton } from '@typeform/embed-react'

import SetUtmParams from '../../../Utilities/SetUtmParams'
import { ConnectTypeformTagManager } from '../../../Utilities/ConnectTypeformTagManager'

export default function TfModal({
    form_id,
    utm_content,
    styles,
    text
}) {
    return (
        <PopupButton
            id={form_id}
            hidden={{
                utm_source: SetUtmParams(window.location.href, 'utm_source'),
                utm_medium: SetUtmParams(window.location.href, 'utm_medium'),
                utm_campaign: SetUtmParams(window.location.href, 'utm_campaign'),
                utm_term: SetUtmParams(window.location.href, 'utm_term'),
                utm_content: { utm_content }
            }}
            medium="snippet"
            style={styles}
            onSubmit={responseId => ConnectTypeformTagManager(responseId, form_id)}
        >
            {text}
        </PopupButton>
    )
}