import { useTranslation } from 'react-i18next';

import LogoUSXS from '../../../Assets/Home/logo_USXS.svg'
import CasosExito from '../../../Assets/svg/casos_exito.svg'

export default function Casos() {

    const [t] = useTranslation('global')

    return (
        <section id="casos" className="py-5">

            <h3 className="text-center mb-4">
                <span className="mx-auto">
                    <img loading='lazy' src={CasosExito} alt="Casos de Éxito" className='casosTitle' />
                </span>
            </h3>

            <article id="usxs">

                <p className="text-white text-center pt-5 col-8 mx-auto">
                    {t('home.casos.usxs.usxs_article')}<strong>{t('home.casos.usxs.usxs_outstanding')}</strong>{t('home.casos.usxs.usxs_text')}
                </p>

                <img src={LogoUSXS} alt="Logo uSound por Samsung" className="logoUSXS mx-auto d-block" />

                <article className='text-center mt-4'>
                    <iframe
                        title='uSound por Samsung'
                        id='videoUXS'
                        className="rounded col-8"
                        src="https://www.youtube.com/embed/RzVfNSZynfs?rel=0&amp;showinfo=0"
                        frameBorder="0"
                        allowFullScreen={true}
                    >
                    </iframe>
                </article>

                <article className='col-12 col-lg-8 d-flex flex-wrap justify-content-between text-white pt-4 mx-auto'>
                    <div className='text-center col-12 col-md-4 px-4'>
                        <h3 className='usxsh3'>2014</h3>
                        <p>
                            {t('home.casos.usxs.usxs_first_column')}
                        </p>
                    </div>
                    <div className='text-center col-12 col-md-4 px-5'>
                        <h3 className='usxsh3'>55</h3>
                        <p>
                            {t('home.casos.usxs.usxs_second_column')}
                        </p>
                    </div>
                    <div className='text-center col-12 col-md-4 px-4'>
                        <h3 className='usxsh3'>+4000</h3>
                        <p>
                            {t('home.casos.usxs.usxs_third_column')}
                        </p>
                    </div>
                </article>

                <button
                    type='button'
                    onClick={() => window.open('https://www.samsung.com/ar/microsite/usound/', '_blank')}
                    className="usxsBtn"
                >
                    {t('home.learn_more_button')}
                </button>

            </article>

        </section>
    )
}