import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import './Styles/App.css';
import Router from './Router';
import LastCompilation from './AppComponents/General/LastCompilation';
import TfPopover from './AppComponents/Typeform/TfPopover';

import { getVersion } from './Utilities/Version';

/**
 * @description Componente principal de la aplicación
 */
function App() {

  const { pathname: path } = useLocation()

  const [t, i18n] = useTranslation("global")

  useEffect(() => {
    hotjar.initialize(3086067)
  }, [])

  const closeSign = env => {
    document.getElementById(env + '-environment').classList.add('d-none');
    if (env === 'testing') {
      setInterval(() => {
        document.getElementById(env + '-environment').classList.remove('d-none');
      }, 10000);
    }
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    console.log('%c' + getVersion(), 'color:white; padding:10px; background-color:red; border-radius: 10px')
  }

  return (
    <>
      {process.env.REACT_APP_ENVIRONMENT === 'testing' &&
        <>
          <div id="testing-environment" className="text-white rounded p-2 position-fixed">
            <p className="mb-0 d-flex justify-content-end">
              <span onClick={() => closeSign('testing')} className="cursor-pointer">X</span>
            </p>
            <strong>
              ENTORNO <br />
              DE <br />
              PRUEBAS
            </strong>
            <LastCompilation />
          </div>
        </>
      }

      {process.env.REACT_APP_ENVIRONMENT === 'development' &&
        <div id="development-environment" className="text-white rounded p-2 position-fixed">
          <p className="mb-0 d-flex justify-content-end">
            <span onClick={() => closeSign('development')} className="cursor-pointer">X</span>
          </p>
          <strong>
            ENTORNO <br />
            DE <br />
            DESARROLLO
          </strong>
        </div>
      }

      <div className="App">
        <Router />
        <TfPopover
          formId={t('home.typeform.chat_id')}
          utmContent={path === '/audiometro' ? "landing_typeform_chat_1" : "Popover"}
        />
      </div>
    </>
  );
}

export default App;
