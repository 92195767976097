import { useTranslation } from "react-i18next";

import ToolsCard from "../Components/ToolsCard";

import Title from '../../../Assets/svg/recurso_masherramientas.svg'
import TitlePT from '../../../Assets/svg/recurso_masherramientas-pt.svg'
import Sonometro from '../../../Assets/svg/ic_sonometro.svg'
import PTA from '../../../Assets/svg/ic_pta.svg'
import Anamnesis from '../../../Assets/svg/ic_anamnesis.svg'
import Smart from '../../../Assets/svg/ic_smart.svg'
import Report from '../../../Assets/Home/recurso_reporte.png'
import Zigzag from "../../../Assets/Components/Zigzag";

export default function Herramientas() {

    const [t, i18n] = useTranslation('global')

    return (
        <section id="herramientas">
            <div className="zigzag-blue">
                <Zigzag color="#0af" />
            </div>
            <div className="my-4 my-lg-5 text-secondary text-center">
                {i18n.language === 'pt' ?
                    <>
                        <img loading="lazy" src={TitlePT} alt={t('home.tools_title')} className="toolsTitle" />
                    </>
                    :
                    <>
                        <img loading="lazy" src={Title} alt={t('home.tools_title')} className="toolsTitle" />
                    </>
                }
            </div>
            <div id="toolCardsContainerParent">
                <div id="toolCardsContainer" className="col-11 col-lg-9 mx-auto">
                    <div className="shadowContainer d-flex mx-auto">
                        <ToolsCard
                            index={0}
                            icon={Sonometro}
                            title={t('home.herramientas.sound_level_meter.title')}
                            description={t('home.herramientas.sound_level_meter.description')}
                            link=""
                        />
                        <ToolsCard
                            index={1}
                            icon={PTA}
                            title={t('home.herramientas.pta.title')}
                            description={t('home.herramientas.pta.description')}
                            link=""
                        />
                        <ToolsCard
                            index={2}
                            icon={Anamnesis}
                            title={t('home.herramientas.anamnesis.title')}
                            description={t('home.herramientas.anamnesis.description')}
                            link=""
                        />
                        <ToolsCard
                            index={3}
                            icon={Smart}
                            title={t('home.herramientas.smart_check.title')}
                            description={t('home.herramientas.smart_check.description')}
                            link=""
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex flex-wrap justify-content-between mb-5 mt-3 pt-lg-5 mx-auto col-11 col-lg-9">
                <div className="col-12 col-lg-8 mx-3 mx-lg-0 text-center">
                    <img src={Report} alt={t('home.herramientas.report.title')} className="reportImg" />
                </div>
                <div className="col-12 col-lg-4 px-3 px-lg-0 pt-lg-5 mt-4 mt-lg-0 text-secondary">
                    <h4 className="reportTitle text-center text-lg-start mb-4 mt-lg-4">
                        {t('home.herramientas.report.title')}
                    </h4>
                    <p>
                        <strong>{t('home.herramientas.report.first.outstanding')}</strong> {t('home.herramientas.report.first.text')}
                    </p>
                    <p>
                        <strong>{t('home.herramientas.report.second.outstanding')}</strong> {t('home.herramientas.report.second.text')}
                    </p>
                    <p>
                        <strong>{t('home.herramientas.report.third.outstanding')}</strong> {t('home.herramientas.report.third.text')}
                    </p>
                    {/* <button className="reportBtn d-block mx-auto mx-lg-0">
                        + INFO
                    </button> */}
                </div>
            </div>
        </section>
    )
}