import { useTranslation } from 'react-i18next'

import CharCard from '../Components/CharCard'
import TfModal from '../../Landing/CTA/TfModal'

import DispAuri from '../../../Assets/Home/disp_auri.svg'
import Portable from '../../../Assets/Home/ic_ultraportable.svg'
import ANC from '../../../Assets/Home/ic_anc.svg'
import Compartir from '../../../Assets/Home/ic_compartir.svg'
import Smart from '../../../Assets/Home/ic_smart.svg'
import Carr1 from '../../../Assets/Home/carousel_caracteristicas/recurso_carrusel_1.png'
import Carr2 from '../../../Assets/Home/carousel_caracteristicas/recurso_carrusel_2.png'
import Carr3 from '../../../Assets/Home/carousel_caracteristicas/recurso_carrusel_3.png'
import Carr4 from '../../../Assets/Home/carousel_caracteristicas/recurso_carrusel_4.png'

export default function Caracteristicas() {

    const [t] = useTranslation('global')

    const commonStyles = {
        fontSize: "1rem",
        padding: "7px 40px",
        border: "none",
        cursor: "pointer",
    }

    return (
        <section id="caracteristicas">

            <article className='text-center'>
                <img loading="lazy" id='product' src={DispAuri} alt="Audiómetro uSound" className='mx-auto' />
            </article>

            <h4 className="text-center text-white mt-5">
                {t('home.caracteristicas.title')}
            </h4>

            <article className='my-5 pt-5'>
                <div className='d-flex flex-wrap justify-content-between text-white col-9 mx-auto'>
                    <div className='d-flex justify-content-around col-12 col-lg-6 mb-5 mb-lg-0'>
                        <CharCard
                            icon={Portable}
                            alt="Ultra portable"
                            first={t('home.caracteristicas.portable.first')}
                            second={t('home.caracteristicas.portable.second')}
                            name="portable"
                            text={t('home.caracteristicas.portable.text')}
                        />
                        <CharCard
                            icon={ANC}
                            alt={t('home.caracteristicas.anc.alt')}
                            first={t('home.caracteristicas.anc.first')}
                            second={t('home.caracteristicas.anc.second')}
                            name="cancelacion"
                            text={t('home.caracteristicas.anc.text')}
                        />
                    </div>
                    <div className='d-flex justify-content-around col-12 col-lg-6 mt-5 mt-lg-0'>
                        <CharCard
                            icon={Compartir}
                            alt={t('home.caracteristicas.share.alt')}
                            first={t('home.caracteristicas.share.first')}
                            second={t('home.caracteristicas.share.second')}
                            name="compartir"
                            text={t('home.caracteristicas.share.text')}
                        />
                        <CharCard
                            icon={Smart}
                            alt={t('home.caracteristicas.update.alt')}
                            first={t('home.caracteristicas.update.first')}
                            second={t('home.caracteristicas.update.second')}
                            name="actualizacion"
                            text={t('home.caracteristicas.update.text')}
                        />
                    </div>
                </div>
            </article>

            <article className="col-11 col-md-10 col-lg-8 col-xxl-5 mx-auto my-5 pt-5">
                <div id="softwareCarrousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000" data-bs-touch="false">
                    <div id="softwareCarrouselInner" className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={Carr1} className="softwareCarrouselItems" alt="" />
                        </div>
                        <div className="carousel-item">
                            <img src={Carr2} className="softwareCarrouselItems" alt="" />
                        </div>
                        <div className="carousel-item">
                            <img src={Carr3} className="softwareCarrouselItems" alt="" />
                        </div>
                        <div className="carousel-item">
                            <img src={Carr4} className="softwareCarrouselItems" alt="" />
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#softwareCarrousel" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#softwareCarrousel" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </article>

            <div className='w-100 text-center mb-5'>
                <TfModal
                    form_id={t('home.caracteristicas.demo_btn')}
                    utm_content="landing_typeform_demo_1"
                    styles={{
                        ...commonStyles,
                        backgroundColor: "#18F0C9"
                    }}
                    text={t('home.caracteristicas.demo_text_btn')}
                />
            </div>

        </section>
    )
}