import FeaturesDiv from './FeaturesDiv'

import Audiometro from '../../../Assets/Landing/audiometro_banner.png'
import Zigzag from '../../../Assets/Components/Zigzag'

export default function Banner() {

    return (
        <div
            id="landing_banner"
            className='d-flex flex-wrap align-items-center'
        >
            <div className='col-lg-6 text-center'>
                <h1 className='mb-0'>
                    ¡Una nueva era en el diagnóstico auditivo!
                </h1>
                <div className='landing_banner_zigzag'>
                    <Zigzag color="#A3FAEB" />
                </div>
                <p className='text-dark banner_text'>
                    Destinado a profesionales especialistas
                    en salud auditiva
                </p>
                <FeaturesDiv color="#fff" />
            </div>
            <div className='col-lg-6 img_container'>
                <img
                    src={Audiometro}
                    alt="Foto del Audiómetro uSound"
                    className='banner_img'
                />
            </div>
        </div>
    )
}