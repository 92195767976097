import { useTranslation } from 'react-i18next'


export default function Hero() {

    const [t, i18n] = useTranslation('global')

    return (
        <section id="hero" className='bg-secondary pb-5 pt-5'>
            <div className='row justify-content-center'>
                <div className='col-10 col-lg-10'>
                    <article className='text-center'>
                        <h4 className='text-center text-white fw-bold fs-1'>
                            {t('home.hero.title')}
                        </h4>
                        <p className='text-center text-white fw-bold fs-2'>
                            {t('home.hero.subtitle')}
                        </p>
                    </article>
                </div>
            </div>
        </section>
    )
}