import { useTranslation } from 'react-i18next'

import CharModal from './CharModal'

import { showModal } from '../../../Utilities/BootstrapApi'

export default function CharCard({
    icon,
    alt,
    first,
    second,
    name,
    text }) {

    const [t] = useTranslation('global')

    return (
        <div className='charCards px-1'>
            <img src={icon} alt={alt} />
            <h4>{first.toUpperCase()}</h4>
            <h4>{second.toUpperCase()}</h4>
            <p className='text'>
                {text}
            </p>
        </div>
    )

} 