import { useTranslation } from 'react-i18next';
import { GoPrimitiveDot } from 'react-icons/go'

import TfModalButton from '../../../AppComponents/Typeform/TfModalButton'

import HardwareTitle from '../../../Assets/svg/hardware_title.svg'
import Bonehead from '../../../Assets/Home/recurso_vincha.png'
import Earphone from '../../../Assets/Home/recurso_auricular.png'

export default function Hardware() {

    const [t, i18n] = useTranslation('global')

    return (
        <section id="hardware" className='pt-sm-5 pb-5'>
            <img loading='lazy' src={HardwareTitle} alt="Hardware" className='hardwareTitle d-block mb-5 mx-auto' />
            <div className='col-10 col-lg-7 ps-0 ps-sm-3 pe-3 py-sm-5 mx-auto text-secondary d-flex'>
                <img loading='lazy' src={Earphone} alt={t('home.hardware.aerial.alt')} className='col-6 earphoneImg' />
                <div className='col-6 position-relative earphoneText'>
                    <h4 className='d-flex align-items-center mb-4'>
                        <GoPrimitiveDot className='earphoneDot' />
                        <div>
                            {i18n.language === 'en' ?
                                <>
                                    <span className='strong-text'>
                                        {t('home.hardware.aerial.type')}
                                    </span>
                                    <span className='italic-text d-block'>
                                        {t('home.hardware.aerial.transducer')}
                                    </span>
                                </> :
                                <>
                                    <span className='italic-text d-block'>
                                        {t('home.hardware.aerial.transducer')}
                                    </span>
                                    <span className='strong-text'>
                                        {t('home.hardware.aerial.type')}
                                    </span>
                                </>
                            }
                        </div>
                    </h4>
                    <p>
                        {t('home.hardware.aerial.first')}
                    </p>
                    <p>
                        {t('home.hardware.aerial.second')}
                    </p>
                    <p>
                        {t('home.hardware.aerial.third')}
                    </p>
                    <p>
                        {t('home.hardware.aerial.fourth')}
                    </p>
                </div>
            </div>
            <div className='d-flex justify-content-center px-5'>
                <div className='col-12 col-lg-6 text-center hardwareText'>
                    <p className='mb-0'>
                        {t('home.hardware.first_text.article')} <strong>{t('home.hardware.first_text.outstanding')}</strong> {t('home.hardware.first_text.text')}
                    </p>
                </div>
            </div>
            <div className='col-10 col-lg-7 pt-5 mx-auto d-flex text-secondary'>
                <div className='col-6 position-relative boneheadText'>
                    <h4 className='d-flex align-items-center'>
                        <GoPrimitiveDot className='boneheadDot' />
                        <div>
                            {i18n.language === 'en' ?
                                <>
                                    <span className='strong-text'>
                                        {t('home.hardware.bone.type')}
                                    </span>
                                    <span className='italic-text d-block'>
                                        {t('home.hardware.bone.transducer')}
                                    </span>
                                </> :
                                <>
                                    <span className='italic-text d-block'>
                                        {t('home.hardware.bone.transducer')}
                                    </span>
                                    <span className='strong-text'>
                                        {t('home.hardware.bone.type')}
                                    </span>
                                </>
                            }
                        </div>
                    </h4>
                    <p>
                        {t('home.hardware.bone.first')}
                    </p>
                    <p>
                        {t('home.hardware.bone.second')}
                    </p>
                    <p>
                        {t('home.hardware.bone.third')}
                    </p>
                </div>
                <img loading='lazy' src={Bonehead} alt={t('home.hardware.bone.alt')} className='col-6 boneheadImg' />
            </div>

            <div className='d-flex justify-content-center px-5 my-4'>
                <div className='col-12 col-lg-6 text-center hardwareText'>
                    <p className='mb-0'>
                        <strong>{t('home.hardware.second_text.outstanding')} </strong>
                        {t('home.hardware.second_text.text')}
                    </p>
                </div>
            </div>

            <article className='col-12 col-sm-6 col-md-4 text-center mx-auto pt-3'>
                <TfModalButton
                    formId={t('home.typeform.popup_id')}
                    styleProp="green sm"
                    text={t('home.hardware.ficha_btn')}
                    utmContent="PopupButton"
                />
            </article>
        </section>
    )
}