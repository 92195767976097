import axios from 'axios'

import { urls } from '../Utilities/Http'

/**
 * 
 * @description Pide al backend las respuestas ingresadas por el usuario en form de Typeform
 * y las agrega a la capa de datos de Tag Manager
 * @param {string} id - ID del profesional a validar
 * @param {string} password - PASSWORD nuevo password de usuario
 */
export const RequestChangePasswordFromPasswordRecovery = async (id, password) => {
    let jsonData = {
        id: id,
        password: password
    }
    
    try {
        const { data } = await axios.post(urls.ChangePasswordFromPasswordRecoveryUrl, jsonData)
        return Promise.resolve(data)
    } catch (err) {
        return(err)
    }
}