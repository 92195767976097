/**
 * 
 * @description Determina si la URL actual corresponde a la Home
 * @param {string} path - URL actual
 * @returns {boolean} 
 */
export const isHomeUrl = path => {
    let splittedAndFiltered = path.split('/').filter(i => i !== '')
    // let found = path.split('/').some(i => i === 'es' || i === 'en' || i === 'pt')
    let found = path.split('/').some(i => i === 'es' || i === 'pt' || i === 'en')
    if ((splittedAndFiltered.length === 1 && found) || path === '/') return true
    return false
}