import { useTranslation } from "react-i18next"

export default function ToolsCard({
    index,
    icon,
    title,
    description,
    link
}) {

    const [t] = useTranslation('global')

    return (
        <article
            className="text-center toolsCard"
            onMouseMove={() => {
                if (window.innerWidth > 769) {
                    let buttons = document.querySelectorAll('.toolsCardBtn')
                    for (let i = 0; i < buttons.length; i++) {
                        if (i !== index) buttons[i].classList.add('d-none')
                        if (i === index) buttons[i].classList.remove('d-none')
                    }
                }
            }}
            onMouseLeave={() => {
                if (window.innerWidth > 769) {
                    let buttons = document.querySelectorAll('.toolsCardBtn')
                    for (let i = 0; i < buttons.length; i++) {
                        buttons[i].classList.add('d-none')
                    }
                }
            }}
        >
            <img src={icon} alt="" className="d-block mx-auto mb-4 toolsCardImg" />
            <strong className="text-white d-block mt-4 mb-2">
                {title}
            </strong>
            <p className="text-white mt-3 mb-0">
                {description}
            </p>
            {/* <button
                id={"toolsCardBtn" + index}
                type="button"
                className="toolsCardBtn"
                onClick={() => window.open(link, '_blank')}
            >
                {t('home.see_more_button')}
            </button> */}
        </article>
    )
}