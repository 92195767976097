import { GiHamburgerMenu } from 'react-icons/gi'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import NavBar from "./NavBar";

import { hideOffcanvas, showOffcanvas } from '../../Utilities/BootstrapApi';
import { isHomeUrl } from '../../Utilities/IsHomeUrl';

/**
 * 
 * @description Cabecera de la página
 */
export default function Header() {

  const { pathname: path } = useLocation()

  useEffect(() => {
    const listener = () => {
      if (window.scrollY > 0) {
        document.getElementById('hambIcon').classList.add('d-none')
      } else {
        document.getElementById('hambIcon').classList.remove('d-none')
      }
      if (isHomeUrl(path)) {
        if (window.scrollY > 0 && window.innerWidth > 991) {
          document.getElementById('header').classList.remove('bg-transparent')
          document.getElementById('header').classList.add('headerBackOnScroll')
        } else {
          document.getElementById('header').classList.add('bg-transparent')
          document.getElementById('header').classList.remove('headerBackOnScroll')
        }
      }
    };

    window.addEventListener("scroll", listener);

    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  window.addEventListener('resize', () => {
    if (window.innerWidth >= 991) {
      hideOffcanvas('hambMenu')
    }
  })

  return (
    <>
      <header
        id="header"
        className={
          `py-3 px-1 px-sm-3 px-lg-5 w-100 position-fixed ${isHomeUrl(path) ?
            'bg-transparent text-white' :
            'bg-light text-dark'
          }`
        }
      >
        {/* <div className="hamburger" data-bs-toggle="offcanvas" href="#hambMenu" role="button" aria-controls="offcanvasExample"> */}
        <div
          id='hambIcon'
          className="hamburger"
          onClick={() => showOffcanvas('hambMenu')}
          role="button"
        >
          <GiHamburgerMenu />
        </div>
        <div id="desktopNav">
          <NavBar />
        </div>
      </header >
      <div className="offcanvas offcanvas-start" tabIndex="-1" id="hambMenu" aria-labelledby="offcanvasExampleLabel">
        <div className="p-3 text-end">
          {/* <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
          <button type="button" onClick={() => hideOffcanvas('hambMenu')} className="btn-close text-reset" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <NavBar />
        </div>
      </div>
    </>
  );
}
