import { useTranslation } from 'react-i18next'

import image_1 from '../../../Assets/Home/anc/image_1.png'
import image_2 from '../../../Assets/Home/anc/image_2.png'
import image_3 from '../../../Assets/Home/anc/image_3.png'

export default function Anc() {

    const [t] = useTranslation('global')

    const commonStyles = {
        fontSize: "1rem",
        padding: "7px 40px",
        border: "none",
        cursor: "pointer",
    }

    return (
        <section id="anc" style={{background: 'linear-gradient(black 65%, #27B491)', paddingBottom: '80px'}}>
            <div className='row justify-content-center pt-5'>
                <div className='col-10 col-lg-10'>
                    <div className='row justify-content-center'>
                        <div className='col-9 col-lg-3'>
                            <img id='image_1' className='w-100' src={image_1} alt="retspl" />
                        </div>
                        <div className='col-12 col-lg-9'>
                            <h4 className="text-white text-center text-lg-start pt-5">
                                {t('home.anc.title_1')}
                            </h4>

                            <p className="text-white text-center text-lg-start pt-5">
                                {t('home.anc.description_1')}
                            </p>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-9 col-lg-3'>
                            <img id='image_2' className='w-100' src={image_2} alt="retspl" />
                        </div>
                        <div className='col-12 col-lg-9'>
                            <h4 className="text-white text-center text-lg-start pt-5">
                                {t('home.anc.title_2')}
                            </h4>

                            <p className="text-white text-center text-lg-start pt-5">
                                {t('home.anc.description_2')}
                            </p>
                            <ul className="text-white text-center text-lg-start list-unstyled ps-4">
                                <li>• {t('home.anc.description_2_item_1')}</li>
                                <li>• {t('home.anc.description_2_item_2')}</li>
                                <li>• {t('home.anc.description_2_item_3')}</li>
                                <li>• {t('home.anc.description_2_item_4')}</li>
                                <li>• {t('home.anc.description_2_item_5')}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-9 col-lg-3'>
                            <img id='image_3' className='w-100' src={image_3} alt="retspl" />
                        </div>
                        <div className='col-12 col-lg-9'>
                            <h4 className="text-white text-center text-lg-start pt-5">
                                {t('home.anc.title_3')}
                            </h4>

                            <p className="text-white text-center text-lg-start pt-5">
                                {t('home.anc.description_3')}
                            </p>
                            <ul className="text-white list-unstyled ps-4">
                                <li>• {t('home.anc.description_3_item_1')}</li>
                                <li>• {t('home.anc.description_3_item_2')}</li>
                                <li>• {t('home.anc.description_3_item_3')}</li>
                                <li>• {t('home.anc.description_3_item_4')}</li>
                                <li>• {t('home.anc.description_3_item_5')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}