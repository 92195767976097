
import { useState } from 'react'
import { PopupButton } from 'react-calendly'

import TfModal from '../CTA/TfModal'
import NfModal from '../CTA/NfModal'

import SetUtmParams from '../../../Utilities/SetUtmParams'

import Misc from '../../../Assets/Home/misc.svg'

export default function Dark() {

    const [show, setShow] = useState(false)

    const [styles, setStyles] = useState({
        width: "80%",
        margin: "0 auto",
        fontSize: ".8rem",
        padding: "10px 30px",
        border: "none",
        cursor: "pointer"
    })

    window.onresize = () => {
        if (window.innerWidth < 768) {
            setStyles({
                ...styles,
                width: "100%",
                padding: "10px 60px"
            })
        } else {
            setStyles({
                ...styles,
                width: "80%",
                padding: "10px 30px"
            })
        }
    }

    return (
        <div id='dark_container'>
            <img src={Misc} alt="" className='miscDown' />
            <div id="dark" className="d-flex flex-wrap align-items-center">
                <div className="col-md-6">
                    <p className="text-center">
                        Súmate a la nueva generación de
                        profesionales que van mas allá
                        y brindan una mejor calidad de
                        vida a sus pacientes.
                    </p>
                </div>
                <div className="col-md-6 mx-auto text-center d-flex flex-column gap-3">
                    <TfModal
                        form_id="CH7orxE7"
                        utm_content="landing_typeform_contact_2"
                        styles={{
                            ...styles,
                            backgroundColor: "#00B0FF"
                        }}
                        text="CONTÁCTANOS"
                    />
                   {/*  <NfModal
                        text="CONTÁCTANOS"
                        form_url="https://form.nativeforms.com/QNOhDUL1jZmYnM1ZWaR1Db"
                        utm_content="landing_native_contact_2"
                        styles={{
                            ...styles,
                            backgroundColor: "#00B0FF"
                        }}
                        show={show}
                        setShow={setShow}
                    /> */}
                    <TfModal
                        form_id="pKcQfhRu"
                        utm_content="landing_typeform_demo_2"
                        styles={{
                            ...styles,
                            backgroundColor: "#18F0C9"
                        }}
                        text="AGENDA UNA DEMO GRATIS"
                    />
                    {/* <PopupButton
                        url="https://calendly.com/usoundaudiometro/demo"
                        styles={{
                            ...styles,
                            backgroundColor: "#18F0C9"
                        }}
                        rootElement={document.getElementById("root")}
                        text="AGENDA UNA DEMO GRATIS"
                        textColor="#ffffff"
                        color="#18F0C9"
                        utm={{
                            utmSource: SetUtmParams(window.location.href, 'utm_source'),
                            utmMedium: SetUtmParams(window.location.href, 'utm_medium'),
                            utmCampaign: SetUtmParams(window.location.href, 'utm_campaign'),
                            utmTerm: SetUtmParams(window.location.href, 'utm_term'),
                            utmContent: 'landing_calendly_demo_2'
                        }}
                    /> */}
                </div>
            </div>
        </div>
    )
}