import { useTranslation } from 'react-i18next'

import LogoHorizontal from '../../Assets/Logos/logo_horizontal_usound.svg'
import Aaip from '../../Assets/Logos/ic_AFIP.svg'
import Fb from '../../Assets/Logos/ic_facebook.svg'
import Ig from '../../Assets/Logos/ic_instagram.svg'
import Ln from '../../Assets/Logos/ic_linkedin.svg'
import Yt from '../../Assets/Logos/ic_youtube.svg'

/**
 * 
 * @description Pie de página
 */
export default function Footer() {

    let year = new Date().getFullYear()

    const { t, i18n } = useTranslation("global")

    return (
        <footer id='footer' className='pt-4 pb-3'>
            <div id='container'>
                <div className='d-flex flex-column flex-column-reverse align-items-center flex-xl-row justify-content-center 
                                justify-content-lg-between'>
                    <div className='d-flex flex-column align-items-center flex-lg-row my-4 my-xl-0 mx-4'>
                        <small className='text-center'>
                            <a
                                href={t('footer.terms_and_conditions_link')}
                                target="_blank"
                                rel='noreferrer'
                                className="text-decoration-none"
                            >
                                {t('footer.terms_and_conditions')}
                            </a>
                        </small>
                        <small className='my-3 my-lg-0 mx-lg-3'>
                            <a
                                href={t('footer.privacy_link')}
                                target="_blank"
                                rel='noreferrer'
                                className="text-decoration-none"
                            >
                                {t('footer.privacy')}
                            </a>
                        </small>
                        { i18n.language === 'pt' && (
                            <small className='my-3 my-lg-0 mx-lg-3'>
                                <a
                                    href="https://drive.google.com/file/d/1sGj9V4lgLq5_Ei1xORF39aJ_RawLsWiD/view"
                                    target="_blank"
                                    rel='noreferrer'
                                    className="text-decoration-none"
                                >
                                    Manual
                                </a>
                            </small>
                        )}
                        <small>
                            <a
                                href="mailto:contacto@newbrickglobaloperationsllc.co"
                                className="text-decoration-none"
                            >
                                contacto@newbrickglobaloperationsllc.co
                            </a>
                        </small>
                        <small className='my-3 my-lg-0 mx-lg-3 d-none'>
                            <a
                                href="tel:+5493886821318"
                                className="text-decoration-none"
                            >
                                +543886821318
                            </a>
                        </small>
                    </div>
                    <div className='d-flex mb-5 mb-lg-0'>
                        <div
                            className='footerMedia'
                            onClick={() => window.open('https://www.youtube.com/channel/UCu5EhgN4WzBcoH3_IW0VoGA', '_blank')}
                        >
                            <img src={Yt} alt="Logo de YouTube" />
                        </div>
                        <div
                            className='footerMedia'
                            onClick={() => window.open('https://www.instagram.com/usound.audiometro/', '_blank')}
                        >
                            <img src={Ig} alt="Logo de Instagram" />
                        </div>
                        <div
                            className='footerMedia'
                            onClick={() => window.open('https://www.linkedin.com/showcase/usound-audi%C3%B3metro/', '_blank')}
                        >
                            <img src={Ln} alt="Logo de LinkedIn" />
                        </div>
                        <div
                            className='footerMedia'
                            onClick={() => window.open('https://www.facebook.com/uSound.Audiometro', '_blank')}
                        >
                            <img src={Fb} alt="Logo de Facebook" />
                        </div>
                    </div>
                </div>
                <p className='text-center pt-4'>
                    <small className='text-muted'>
                        Copyright © {year}. Newbrick Global Operations LLC.
                    </small>
                </p>
                <p className='text-center pt-4'>
                    <img
                        id='footerLogo'
                        src={LogoHorizontal}
                        alt="Logo uSound Audiómetro"
                    />
                </p>
                <p className='text-center'>
                    <img id='aaip' src={Aaip} alt="Logo AAIP" />
                </p>
            </div>
        </footer>
    )
}