import { PopupButton } from '@typeform/embed-react'

import { ConnectTypeformTagManager } from '../../Utilities/ConnectTypeformTagManager'
import SetUtmParams from '../../Utilities/SetUtmParams'

/**
 * 
 * @description Componente de Typeform - Botón que abre un form en un modal
 * @param {string} styleProp - Estilos generales del botón
 * @param {string} text - Texto a mostrar en el botón
 * @param {string} formId - ID del formulario de Typeform que llama el botón
 */
export default function TfModalButton({
    styleProp,
    text,
    formId,
    utmContent
}) {

    return (
        <PopupButton
            id={formId}
            hidden={{
                utm_source: SetUtmParams(window.location.href, 'utm_source'),
                utm_medium: SetUtmParams(window.location.href, 'utm_medium'),
                utm_campaign: SetUtmParams(window.location.href, 'utm_campaign'),
                utm_term: SetUtmParams(window.location.href, 'utm_term'),
                utm_content: { utmContent }
            }}
            medium="snippet"
            className={`typeformButton general ${styleProp}`}
            onSubmit={responseId => ConnectTypeformTagManager(responseId, formId)}
        >
            {text}
        </PopupButton>
    )
}