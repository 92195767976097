import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react';

export default function Referenia() {
    const [t] = useTranslation('global')
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (isModalOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [isModalOpen]);

    return (
        <section id="referencia" className='bg-light pb-5'>
            <div className='row justify-content-center'>
                <div className='col-12 col-lg-10 pt-5 pb-5'>
                    <button
                        type='button'
                        onClick={() => setIsModalOpen(true)}
                        className="usxsBtn"
                        style={{
                            backgroundColor: "#18F0C9"
                        }}
                    >
                        {t('home.referencia.title')}
                    </button>

                    {/* Modal de Bootstrap */}
                    <div 
                        className={`modal fade ${isModalOpen ? 'show d-block' : ''}`} 
                        tabIndex="-1"
                        style={{
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            top: '0', // Sobrescribimos el top: 30%
                            display: 'flex !important',
                            alignItems: 'center',
                            height: '100vh'
                        }}
                    >
                        <div className="modal-dialog modal-dialog-centered modal-lg" style={{ margin: 'auto' }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{t('home.referencia.title')}</h5>
                                    <button 
                                        type="button" 
                                        className="btn-close" 
                                        onClick={() => setIsModalOpen(false)}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                                    <div>
                                        <p className="fw-bold">
                                            {t('home.referencia.subtitle_1')}
                                        </p>
                                        <p className="pt-2">
                                            {t('home.referencia.description_1_1')}
                                        </p>
                                        <p className="pt-2">
                                            {t('home.referencia.description_1_2')}
                                        </p>
                                        <p className="pt-2">
                                            {t('home.referencia.description_1_3')}
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <p className="fw-bold">
                                            {t('home.referencia.subtitle_2')}
                                        </p>
                                        <p className="pt-2">
                                            {t('home.referencia.description_2_1')}
                                        </p>
                                        <p className="pt-2">
                                            {t('home.referencia.description_2_2')}
                                        </p>
                                        <p className="pt-2">
                                            {t('home.referencia.description_2_3')}
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <p className="fw-bold">
                                            {t('home.referencia.subtitle_3')}
                                        </p>
                                        <p className="pt-2">
                                            {t('home.referencia.description_3_1')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}