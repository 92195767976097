import { useTranslation } from "react-i18next"

import Prev from '../../../Assets/svg/ic_flecha_white.svg'
import Next from '../../../Assets/svg/ic_flecha_blue.svg'

export default function EvaluationsCard({
    id,
    elements
}) {

    const [t] = useTranslation('global')

    if (elements.length === 1) return (
        elements.map((el, idx) => {
            return (
                <article key={idx} className="evalCard p-3">
                    <img
                        className="evalCardVideo"
                        src={el.video}
                        alt=""
                    />
                    <div className="d-flex evalCardContent">
                        <img src={el.icon} alt="" className="evalImg" />
                        <div className="ms-2">
                            <span className="d-block text-secondary evalCardEstudio">{t('home.estudios.general')}</span>
                            <span className="text-secondary evalCardName">{el.name}</span>
                        </div>
                    </div>
                    <p className="text-secondary mt-3 evalCardDesc">{el.description}</p>
                    {/* <div className="text-center mt-4">
                            <button className="cardBtn fw-bold">
                                {t('home.see_more_button')}
                            </button>
                        </div> */}
                </article>
            )
        })
    )

    return (
        <article className="evalCard p-3">
            <div id={id} className="carousel slide" data-bs-interval="false">
                <div className="carousel-inner">
                    {elements.map((el, idx) => {
                        return (
                            <div key={idx} className={idx === 0 ? "carousel-item active mb-5" : "carousel-item mb-5"}>
                                <img
                                    className="evalCardVideo"
                                    src={el.video}
                                    alt=""
                                />
                                <div className="d-flex evalCardContent">
                                    <img src={el.icon} alt="" className="evalImg" />
                                    <div className="ms-2">
                                        <span className="d-block text-secondary evalCardEstudio">{t('home.estudios.general')}</span>
                                        <span className="text-secondary evalCardName">{el.name}</span>
                                    </div>
                                </div>
                                <p className="text-secondary mt-3 evalCardDesc">{el.description}</p>
                                {/* <button className="cardBtn fw-bold">
                                    {t('home.see_more_button')}
                                </button> */}
                            </div>
                        )
                    })}
                </div>
                <img src={Prev} alt="" className="arrow arrow-prev" data-bs-target={'#' + id} data-bs-slide="prev" />
                <img src={Next} alt="" className="arrow arrow-next" data-bs-target={'#' + id} data-bs-slide="next" />
            </div>
        </article>
    )
}