import ContentLoader from "react-content-loader"

/**
 * 
 * @description Efecto de carga para lazy laoding
 * @param {Object} props - Configuración del efecto de carga 
 */
const ProfileLoader = (props) => (
  <ContentLoader 
    speed={5}
    width={337.25}
    height={200}
    viewBox="0 0 337.25 200"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="149" cy="52" r="49" /> 
    <rect x="81" y="154" rx="3" ry="3" width="143" height="9" /> 
    <rect x="82" y="121" rx="3" ry="3" width="139" height="9" />
  </ContentLoader>
)

export default ProfileLoader