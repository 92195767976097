import { useTranslation } from "react-i18next"

export default function EvaluationSlide({
    elements
}) {

    const [t] = useTranslation('global')

    return (
        <article className="d-flex overflow-scroll">
            {elements.map((el, idx) => {
                return (
                    <div key={idx} className={
                        `d-flex flex-column justify-content-center shadow evaluationSlide mx-3 ${elements.length === 1 ? 'fixedSlide' : ''}`
                    }>
                        <div className="d-flex evalSlideContent">
                            <img src={el.icon} alt="" className="evalSlideIcon" />
                            <div className="ms-4">
                                <span className="d-block text-secondary evalSlideEstudio">{t('home.estudios.general')}</span>
                                <span className="text-secondary evalSlideTitle"><strong>{el.name}</strong></span>
                            </div>
                        </div>
                        <p className="text-secondary text-center mt-3 evalSlideDesc">
                            {el.description}
                        </p>
                        {/* <div className="text-center mt-4">
                            <button className="slideBtn fw-bold">
                                {t('home.see_more_button')}
                            </button>
                        </div> */}
                    </div>
                )
            })}
        </article>
    )
}