import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import App from "./App";

import global_en from "./Translations/en/global.json";
import global_es from "./Translations/es/global.json";
import global_pt from "./Translations/pt/global.json";
import { getBrowserLanguage } from "./Utilities/Language";

import "@fontsource/roboto";
import "@fontsource/montserrat";

/**
 * @description Función para detectar el idioma en parámetro de la url
 * @returns {string} defaultLanguage - Siglas del idioma por defecto del sitio
 */
let setDefaultLanguage = () => {
  if (window.location.pathname === '/') {
    return getBrowserLanguage();
  }
  if (window.location.pathname.split("/")[1] === "es") {
    return "es";
  }
  if (window.location.pathname.split("/")[1] === "pt") {
    return "pt";
  }
  return "en";
};

i18next.init({
  interpolation: { escapeValue: false },
  lng: setDefaultLanguage(),
  resources: {
    en: {
      global: global_en,
    },
    es: {
      global: global_es,
    },
    pt: {
      global: global_pt,
    },
  },
});

ReactDOM.render(
  <BrowserRouter>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
