import { useTranslation } from 'react-i18next'

import EvaluationsCard from '../Components/EvaluationsCard'
import EvaluationSlide from '../Components/EvaluationsSlide'

import Misc from '../../../Assets/Home/misc.svg'
import Quote from '../../../Assets/Home/Recurso_Destacado.svg'
import QuotePT from '../../../Assets/Home/recurso_destacado-pt.svg'
import Aerea from '../../../Assets/EvaluationsIcons/ic_aerea.svg'
import AereaAuto from '../../../Assets/EvaluationsIcons/ic_aereaauto.svg'
import Oseo from '../../../Assets/EvaluationsIcons/ic_oseo.svg'
import OseoAuto from '../../../Assets/EvaluationsIcons/ic_oseoauto.svg'
import LDL from '../../../Assets/EvaluationsIcons/ic_ldl.svg'
import SISI from '../../../Assets/EvaluationsIcons/ic_sisi.svg'
import Fowler from '../../../Assets/EvaluationsIcons/ic_fowler.svg'
import Logo from '../../../Assets/EvaluationsIcons/ic_logoaudiometria.svg'
import Tinnitumetria from '../../../Assets/EvaluationsIcons/ic_tinnitumetria.svg'
import Tinnitus from '../../../Assets/EvaluationsIcons/ic_tinnitus.svg'

import LDLVideo from '../../../Assets/Home/carousel_estudios/ldl_a22.png'
import LogoVideo from '../../../Assets/Home/carousel_estudios/logo_a22.png'
import OseaManualVideo from '../../../Assets/Home/carousel_estudios/Oseamanual_a22.png'
import OseaAutoVideo from '../../../Assets/Home/carousel_estudios/Oseaauto_a22.png'
import AereaManualVideo from '../../../Assets/Home/carousel_estudios/Aereamanual_a22.png'
import AereaAutoVideo from '../../../Assets/Home/carousel_estudios/aereaauto_a22.png'
import TinniVideo from '../../../Assets/Home/carousel_estudios/tinitumetria_a22.png'
import SisiVideo from '../../../Assets/Home/carousel_estudios/sisi_a22.png'
import FowlerVideo from '../../../Assets/Home/carousel_estudios/fowler_a22.png'
import IncapacidadVideo from '../../../Assets/Home/carousel_estudios/incapacidad_a22.png'

export default function Estudios() {

    const [t, i18n] = useTranslation('global')

    return (
        <section id="estudios" className='bg-light pb-5'>
            <img src={Misc} alt="" className='miscDown' />

            <article className='text-center'>
                {i18n.language === 'pt' ?
                    <>
                        <img id='quote' src={QuotePT} alt="Cita" />
                    </>
                    :
                    <>
                        <img id='quote' src={Quote} alt="Cita" />
                    </>
                }
                
            </article>

            <article id='slidersSection'>
                <h4 className='text-center text-info fw-bold fs-1'>
                    {i18n.language === 'en' ?
                        <>
                            {t('home.estudios.liminal_tests') + ' ' + t('home.estudios.plural_general')}
                        </>
                        :
                        <>
                            {t('home.estudios.plural_general') + ' ' + t('home.estudios.liminal_tests')}
                        </>
                    }
                </h4>
                <EvaluationSlide
                    elements={[
                        {
                            icon: Aerea,
                            name: t('home.estudios.aerial_manual.name'),
                            description: t('home.estudios.aerial_manual.description'),
                            link: ''
                        },
                        {
                            icon: AereaAuto,
                            name: t('home.estudios.aerial_automatic.name'),
                            description: t('home.estudios.aerial_automatic.description'),
                            link: ''
                        },
                        {
                            icon: Oseo,
                            name: t('home.estudios.bone_manual.name'),
                            description: t('home.estudios.bone_manual.description'),
                            link: ''
                        },
                        {
                            icon: OseoAuto,
                            name: t('home.estudios.bone_automatic.name'),
                            description: t('home.estudios.bone_automatic.description'),
                            link: ''
                        },
                    ]}
                />
                <h4 className='text-center text-info fw-bold mt-5 fs-1'>
                    {i18n.language === 'en' ?
                        <>
                            {t('home.estudios.supra_liminal_tests') + ' ' + t('home.estudios.plural_general')}
                        </>
                        :
                        <>
                            {t('home.estudios.plural_general') + ' ' + t('home.estudios.supra_liminal_tests')}
                        </>
                    }
                </h4>
                <EvaluationSlide
                    elements={[
                        {
                            icon: LDL,
                            name: t('home.estudios.ldl.name'),
                            description: t('home.estudios.ldl.description'),
                            link: ''
                        },
                        {
                            icon: SISI,
                            name: t('home.estudios.sisi.name'),
                            description: t('home.estudios.sisi.description'),
                            link: ''
                        },
                        {
                            icon: Fowler,
                            name: t('home.estudios.fowler.name'),
                            description: t('home.estudios.fowler.description'),
                            link: ''
                        },
                    ]}
                />
                <h4 className='text-center text-info fw-bold mt-5 fs-1'>
                    {i18n.language === 'en' ?
                        <>
                            {t('home.estudios.vocal_tests') + ' ' + t('home.estudios.plural_general')}
                        </>
                        :
                        <>
                            {t('home.estudios.plural_general') + ' ' + t('home.estudios.vocal_tests')}
                        </>
                    }
                </h4>
                <EvaluationSlide
                    elements={[
                        {
                            icon: Logo,
                            name: t('home.estudios.logo.name'),
                            description: t('home.estudios.logo.description'),
                            link: ''
                        }
                    ]}
                />
                <h4 className='text-center text-info fw-bold mt-5 fs-1'>
                    {t('home.estudios.other_tests') + ' ' + t('home.estudios.plural_general')}
                </h4>
                <EvaluationSlide
                    elements={[
                        {
                            icon: Tinnitumetria,
                            name: t('home.estudios.tinnitumetry.name'),
                            description: t('home.estudios.tinnitumetry.description'),
                            link: ''
                        },
                        {
                            icon: Tinnitus,
                            name: t('home.estudios.tinnitus.name'),
                            description: t('home.estudios.tinnitus.description'),
                            link: ''
                        },
                    ]}
                />
            </article>
            <article id='cardsSection' className='d-flex flex-wrap justify-content-between col-12 col-xl-11 mx-auto'>
                <div className='evalCardContainer'>
                    <h4 className='text-center text-info fw-bold mb-4'>
                        {i18n.language === 'en' ?
                            <>
                                <span className='d-block'>{t('home.estudios.liminal_tests')}</span>
                                <span>{t('home.estudios.plural_general')}</span>
                            </> :
                            <>
                                <span className='d-block'>{t('home.estudios.plural_general')}</span>
                                <span>{t('home.estudios.liminal_tests')}</span>
                            </>
                        }
                    </h4>
                    <EvaluationsCard
                        id="first"
                        elements={[
                            {
                                video: AereaManualVideo,
                                icon: Aerea,
                                name: t('home.estudios.aerial_manual.name'),
                                description: t('home.estudios.aerial_manual.description'),
                                link: ''
                            },
                            {
                                video: AereaAutoVideo,
                                icon: AereaAuto,
                                name: t('home.estudios.aerial_automatic.name'),
                                description: t('home.estudios.aerial_automatic.description'),
                                link: ''
                            },
                            {
                                video: OseaManualVideo,
                                icon: Oseo,
                                name: t('home.estudios.bone_manual.name'),
                                description: t('home.estudios.bone_manual.description'),
                                link: ''
                            },
                            {
                                video: OseaAutoVideo,
                                icon: OseoAuto,
                                name: t('home.estudios.bone_automatic.name'),
                                description: t('home.estudios.bone_automatic.description'),
                                link: ''
                            },
                        ]}
                    />
                </div>
                <div className='evalCardContainer'>
                    <h4 className='text-center text-info fw-bold mb-4'>
                        {i18n.language === 'en' ?
                            <>
                                <span className='d-block'>{t('home.estudios.supra_liminal_tests')}</span>
                                <span>{t('home.estudios.plural_general')}</span>
                            </> :
                            <>
                                <span className='d-block'>{t('home.estudios.plural_general')}</span>
                                <span>{t('home.estudios.supra_liminal_tests')}</span>
                            </>
                        }
                    </h4>
                    <EvaluationsCard
                        id="second"
                        elements={[
                            {
                                video: LDLVideo,
                                icon: LDL,
                                name: t('home.estudios.ldl.name'),
                                description: t('home.estudios.ldl.description'),
                                link: ''
                            },
                            {
                                video: SisiVideo,
                                icon: SISI,
                                name: t('home.estudios.sisi.name'),
                                description: t('home.estudios.sisi.description'),
                                link: ''
                            },
                            {
                                video: FowlerVideo,
                                icon: Fowler,
                                name: t('home.estudios.fowler.name'),
                                description: t('home.estudios.fowler.description'),
                                link: ''
                            },
                        ]}
                    />
                </div>
                <div className='evalCardContainer'>
                    <h4 className='text-center text-info fw-bold mb-4'>
                        {i18n.language === 'en' ?
                            <>
                                <span className='d-block'>{t('home.estudios.vocal_tests')}</span>
                                <span>{t('home.estudios.plural_general')}</span>
                            </> :
                            <>
                                <span className='d-block'>{t('home.estudios.plural_general')}</span>
                                <span>{t('home.estudios.vocal_tests')}</span>
                            </>
                        }
                    </h4>
                    <EvaluationsCard
                        elements={[
                            {
                                video: LogoVideo,
                                icon: Logo,
                                name: t('home.estudios.logo.name'),
                                description: t('home.estudios.logo.description'),
                                link: ''
                            }
                        ]}
                    />
                </div>
                <div className='evalCardContainer'>
                    <h4 className='text-center text-info fw-bold mb-4'>
                        <span className='d-block'>{t('home.estudios.other_tests')}</span>
                        <span>{t('home.estudios.plural_general')}</span>
                    </h4>
                    <EvaluationsCard
                        id="fourth"
                        elements={[
                            {
                                video: TinniVideo,
                                icon: Tinnitumetria,
                                name: t('home.estudios.tinnitumetry.name'),
                                description: t('home.estudios.tinnitumetry.description'),
                                link: ''
                            },
                            {
                                video: IncapacidadVideo,
                                icon: Tinnitus,
                                name: t('home.estudios.tinnitus.name'),
                                description: t('home.estudios.tinnitus.description'),
                                link: ''
                            },
                        ]}
                    />
                </div>
            </article>
        </section>
    )
}