import Modal from 'react-bootstrap/Modal';

export default function VideoModal({
    showVideo,
    setShowVideo
}) {
    return (
        <Modal
            show={showVideo}
            onHide={() => setShowVideo(false)}
            size="xl"
            style={{
                top: "5%"
            }}
        >
            <iframe
                title='Video uSound Audiómetro'
                id=''
                className="rounded"
                style={{
                    height: '500px'
                }}
                src="https://www.youtube.com/embed/zZ5Ovf41BAg?rel=0&amp;showinfo=0"
                frameBorder="0"
                allowFullScreen={true}
            >
            </iframe>
        </Modal>
    )
}