import axios from 'axios'

import { urls } from './Http'

/**
 * 
 * @description Pide al backend las respuestas ingresadas por el usuario en form de Typeform
 * y las agrega a la capa de datos de Tag Manager
 * @param {string} id - ID del profesional a validar
 * @param {string} token - TOKEN generado al solicitar cambio de contraseña
 */
export const RequestVerifyTokenPasswordRecovery = async (id, token) => {
    let jsonData = {
        id: id,
        token: token
    }
    
    try {
        const { data } = await axios.post(urls.verifyTokenPasswordRecoveryUrl, jsonData)
        return data
    } catch (err) {
        return(err)
    }
}